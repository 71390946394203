export const adminRoutes = (t) => [
    {
        endPoint: '/admin/dashboard',
        authRequire: true,
        addInAdminSideBar: true,
        active: ['/admin/dashboard'],
        title: t('dashboard.title'),
        icon: 'icon icon-widgets',
        userTypes: ['admin'],
        moduleKey: 'dashboard',
        key: 'dashboard'
      },
      {
        endPoint: '/admin/clinic-list',
        authRequire: true,
        addInAdminSideBar: true,
        active: ['/admin/clinic-list'],
        title: t('clinics.title'),
        icon: 'icon ico-pharmacy',
        userTypes: ['admin'],
        moduleKey: 'clinics',
        key: 'clinicList'
      },
      {
        endPoint: 'users',
        authRequire: true,
        addInAdminSideBar: true,
        active: [
          '/users',
          '/admin/doctor-list',
          '/admin/patient-list',
          // '/admin/nurse-list'
        ],
        title: t('users.title'),
        icon: 'icon icon-user',
        userTypes: ['admin'],
        moduleKey: 'users',
        key: ['users'],
        child: [
          {
            endPoint: '/admin/doctor-list',
            authRequire: true,
            addInAdminSideBar: true,
            active: ['/admin/doctor-list'],
            title: t('users.doctors'),
            icon: '',
            userTypes: ['admin'],
            moduleKey: 'users',
            key: 'doctorlist'
          },
          {
            endPoint: '/admin/patient-list',
            authRequire: true,
            addInAdminSideBar: true,
            active: ['/admin/patient-list', '/admin/add-patient', '/patient-detail/:id', '/admin/patient-edit/:id'],
            title: t('users.patients'),
            icon: '',
            userTypes: ['admin'],
            moduleKey: 'users',
            key: 'patientlist'
          },
          // {
          //   endPoint: '/admin/nurse-list',
          //   authRequire: true,
          //   addInAdminSideBar: false,
          //   active: ['/admin/nurse-list'],
          //   title: t('users.nurse'),
          //   icon: '',
          //   userTypes: checkPermission(permissions, 'users'),
          //   moduleKey: 'users',
          //   key: 'nurselist'
          // }
        ]
      },
    
      // {
      //   endPoint: '/admin/clinic-admin',
      //   authRequire: true,
      //   addInAdminSideBar: true,
      //   active: ['/admin/clinic-admin'],
      //   title: t('clinics.clinicAdmin'),
      //   icon: 'icon ico-doctor',
      //   userTypes: checkPermission(permissions, 'clinicAdmin'),
      //   moduleKey: 'clinicAdmin',
      //   key: 'clinicadminlist'
      // },
      {
        endPoint: '/admin/discharge-summary',
        authRequire: true,
        addInAdminSideBar: true,
        active: ['/admin/discharge-summary'],
        title: t('dischargeSummary.title'),
        icon: 'icon ico-notepad',
        userTypes: ['admin'],
        moduleKey: 'dischargeSummary',
        key: 'dischargesummarylist'
      },
      {
        endPoint: '/admin/speciality',
        authRequire: true,
        addInAdminSideBar: true,
        active: ['/admin/speciality'],
        title: t('speciality.title'),
        icon: 'icon ico-safe',
        userTypes: ['admin'],
        moduleKey: 'speciality',
        key: 'speciality'
      },
      {
        endPoint: 'Report',
        authRequire: true,
        addInAdminSideBar: true,
        active: [
          '/report',
          '/admin/report',
          '/admin/audit-report',
        ],
        title: t('common.report'),
        icon: 'icon ico-file',
        userTypes: ['admin'],
        moduleKey: 'urgentCare',
        key: ['report'],
        child: [
          {
            endPoint: '/admin/report',
            authRequire: true,
            addInAdminSideBar: true,
            active: ['/admin/report'],
            title: t('common.report'),
            icon: '',
            userTypes: ['admin'],
            key: 'reports'
          },
          {
            endPoint: '/admin/audit-report',
            authRequire: true,
            addInAdminSideBar: true,
            active: ['/admin/audit-report'],
            title: t('common.auditReport'),
            icon: '',
            userTypes: ['admin'],
            key: 'auditReport'
          },
        ]
      },
      {
        endPoint: '/admin/add-clinic',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/add-clinic'],
        userTypes: ['admin'],
        moduleKey: 'clinics',
      },
      {
        endPoint: '/admin/view-clinic/:id',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/view-clinic/:id'],
        userTypes: ['admin'],
        moduleKey: 'clinics',
      },
      {
        endPoint: '/admin/edit-clinic/:id',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/edit-clinic/:id'],
        userTypes: ['admin'],
        moduleKey: 'clinics',
      },
      {
        endPoint: '/admin/add-patient',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/add-patient'],
        userTypes: ['admin'],
        moduleKey: 'patients',
      },
    
      {
        endPoint: '/admin/patient-detail/:id',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/patient-detail/:id'],
        userTypes: ['admin'],
        moduleKey: 'patients'
      },
      {
        endPoint: '/admin/patient-edit/:id',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/patient-edit/:id'],
        userTypes: ['admin'],
        moduleKey: 'patients'
      },
      {
        endPoint: '/admin/doctor-add',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/doctor-add'],
        userTypes: ['admin'],
        moduleKey: 'doctors',
      },
      {
        endPoint: '/admin/doctor-edit/:id',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/doctor-edit/:id'],
        userTypes: ['admin'],
        moduleKey: 'doctors',
      },
      {
        endPoint: '/admin/view-doctor/:id',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/view-doctor/:id'],
        userTypes: ['admin'],
        moduleKey: 'doctor'
      },
      {
        endPoint: '/admin/profile',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/profile'],
        userTypes: ['admin'],
        moduleKey: 'profile'
      },
      {
        endPoint: '/admin/notification',
        authRequire: true,
        addInAdminSideBar: false,
        active: ['/admin/notification'],
        title: t('notification.title'),
        icon: 'icon ico-comment',
        userTypes: ['admin'],
      }
]