import React from 'react'

export default function GlobalLoader() {
  return (
    <div id='preloader'>
      <div className='inner'>
        <img src='assets/images/loading.gif' alt='loader' />
      </div>
    </div>
  )
}
