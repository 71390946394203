import { createSlice } from '@reduxjs/toolkit'

import { logoutAuth } from '../../services/auth'
import logger from '../../utilities/logger'
import modalNotification from '../../utilities/notifications'
export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    userData: {}
  },
  reducers: {
    loginAction: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        userData: action.payload
      }
    },

    logoutAction: (state) => {
      return {
        ...state,
        isLoggedIn: false,
        userData: {}
      }
    },
    updateUserDataAction: (state, action) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.payload
        }
      }
    }
  }
})

export const {
  loginAction,
  updateUserDataAction,
  logoutAction
} = authSlice.actions

// export const onlogin = (payload) => async (dispatch) => {
//   payload.userDetails['roleName'] = 'admin'
//   try {
//     let res = await authListService(payload.userDetails)
//     // console.log(res, 'onlogin')
//     if (res && res.success) {
//       let userData = res.data
//       // console.log(res.data, 'userData')
//       setSessionStorageToken(res.data)
//       modalNotification({
//         type: 'success',
//         message: 'Login',
//         description: res.message
//       })
//       dispatch(
//         loginAction({
//           userId: userData.id,
//           userName: `${userData ? userData.first_name : '-'} ${
//             userData && userData.last_name != null ? userData.last_name : ''
//           }`,
//           userType: userData.user_type,
//           status: userData.success,
//           isActive: userData.can_user_login,
//           photo: userData.photo
//         })
//       )
//     }
//   } catch (error) {
//     // console.log("error",error)
//     logger(error)
//   }
// }
export const logout = (value,history,setIsDisabled,message) => async (dispatch) => {
  try {
    setIsDisabled(true)
    //  await logoutAuth();
    let res = await logoutAuth({})
    if (res && res.message) {
      modalNotification({
        type: 'success',
        message: message,
        description: res.message
      })
    }
    dispatch(logoutAction())
    value === 'admin' ? history.push("/admin") : history.push("/")
    setIsDisabled(false)
  } catch (error) {
    // console.log("error",error)
    setIsDisabled(false)
    logger(error)
  }
}

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn

export const selectUserData = (state) => {
  if (
    selectIsLoggedIn(state) &&
    Object.keys(state.auth.userData).length &&
    Object.keys(state.auth.userData).length
  ) {
    return state.auth.userData
  }
  return {}
}

export const selectUserToken = (state) => {
  if (selectIsLoggedIn(state) && Object.keys(state.auth.userData).length) {
    return state.auth.userData.token
  }
  return false
}

export default authSlice.reducer
