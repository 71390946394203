import React from 'react'
import Routes from './routes'

import "../src/styles/vendor.css";
import "../src/styles/main.less"


function App() {
  return <Routes />
}

export default App
