import React from 'react'


export default function FullPageLoader() {
  return (
    <div className="pageLoader">
      <div className="pageLoader-inner">
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
        {/* <span>Loading...</span> */}
      </div>
    </div>
  )
}
