import React,{useEffect, useState} from "react";
import {Layout, Popover} from "antd";
import {Link} from "react-router-dom";
import CustomScrollbars from "../common/customScrollbars";
import languageData from "./languageData";
import UserInfo from "./userInfo";
import { useTranslation } from 'react-i18next'
import Auxiliary from "../common/auxiliary";
import config from '../../config'
import AppNotification from '../appNotification'
import { useSelector, useDispatch } from "react-redux";
import { selectNotificationCount,selectMessageCount, getNotificationCount } from "../../redux/common/commonSlice";
import { selectUserData } from "../../redux/auth/authSlice";

const {Header} = Layout;
const Topbar = () => {
  const [show] = useState(true)
  const { t } = useTranslation()
  const notificationCount = useSelector(selectNotificationCount);
  const messageCount = useSelector(selectMessageCount)
  const userData = useSelector(selectUserData);
  const dispatch = useDispatch();


  const sidebarOpen = () => {
    document.getElementsByTagName('body')[0]
    .classList.add('showSidebar')
  }

  useEffect(()=>{
    let setIntervalId = setInterval(()=>{
      dispatch(getNotificationCount())
    },6000)

    return ()=> clearInterval(setIntervalId)
  },[])

  const languageMenu = () => (
    <div className="popover-lang-scroll">
      <ul className="sub-popover">
        {languageData.map(language =>
          <li className="media pointer" 
            key={JSON.stringify(language)} 
            onClick={() => {
              localStorage.setItem(`${config.NAME_KEY}:language`,language.locale)
              window.location.reload(false);
            }
          }>
            <i className={`flag flag-24 mr-2 flag-${language.icon}`}/>
            <span className="language-text">{t(`language.${language.name}`)}</span>
          </li>
        )}
      </ul>
    </div>);

    

  return (
    <Header>
      <div className="linebar mr-3 d-xl-none">
          <i className="icon-btn icon icon-menu"
             onClick={(e) => sidebarOpen()}
          />
        </div> 
      <Link to="/" className="d-block d-xl-none pointer">
        <img alt="" src="/assets/images/w-logo.png"/></Link>   
      <ul className="header-notifications ml-auto">
      {userData.userType !== 'admin' &&
        <li className="notify notify-message">
          <Link to={`/${userData.userType}/message`} className="pointer d-block"><i className="icon icon-chat"/>
          {messageCount >0 &&
            <span className="notificationCount">{messageCount}</span>}
            </Link>
        </li> }
      <Auxiliary>
            <li className="notify">
              <Popover overlayClassName="popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                       trigger="click">
                <span className="pointer d-block"><i className="icon icon-notification"/></span>
                {notificationCount > 0 &&
                <span className="notificationCount">{notificationCount}</span>}
              </Popover>
            </li>           
          </Auxiliary>  
        <li className="language">
          <Popover overlayClassName="popover-horizantal" placement="bottomRight" content={languageMenu()}
                   trigger="click">
                <span className="pointer flex-row align-items-center">
                  <i className={`flag flag-24 flag-${t('common.flag')}`}/>
                  <span className="pl-2 language-name">{t('patients.language')}</span>
                  <i className="icon icon-chevron-down pl-2"/>
                </span>
          </Popover>
        </li>

        <Auxiliary>
          <li className="user-nav"><UserInfo/></li>
        </Auxiliary>
      </ul>
    </Header>
  );
};

export default Topbar;
