import io from 'socket.io-client'
import config from '../config'

function log(type, messages, data = {}) {
    let dateTime = new Date().toISOString();
    let message = `${dateTime} - ${messages}`;

    switch (type) {
        case 'info':
            console.info(message, data);
            break;
        case 'error':
            console.error(message, data);
            break;
        case 'warn':
            console.warn(message, data);
            break;
        default:
            console.log(message, data);
    }
}
export let prortc = {};


if ('undefined' === typeof module) {
    prortc = this.prortc = {};
} 
prortc.socket = null;
prortc.selfSocketId = null;
prortc.iceServers = null;
prortc.peerConnections = {};
prortc.sockets = [];
prortc.streams = [];
prortc._events = {};
prortc.userRole = '';
prortc.remoteStreams = {};
prortc.otherSocketId = {};
prortc.IsConnected = false;
prortc.isConnecting = false;
prortc.isMediaFoundError = false;
prortc.isReconnecting = false;
prortc.isReconnected = false;


/**
 * Initialize event
 * @param eventName
 * @param callback
 */
prortc.on = function (eventName, callback) {
    prortc._events[eventName] = prortc._events[eventName] || [];
    prortc._events[eventName].push(callback);
};

/**
 * Execute event
 * @param eventName
 */
prortc.emit = function (eventName) {
    let events = prortc._events[eventName];
    let args = Array.prototype.slice.call(arguments, 1);
    if (!events) {
        return;
    }
    for (let i = 0, len = events.length; i < len; i++) {
        events[i].apply(null, args);
    }
};

/**
 * User join signaling room
 * @param message
 */
prortc.joinRoom = function (message) {
    if (prortc.socket && message.room) {
        prortc.socket.emit('join_room', message);
        log('info', 'join room', message);

        prortc.socket.on('room_full', function () {
            log('info', 'room_full');
        })

        prortc.emit('connect');
    }
}


/** 
 * Connect to signaling server
 * @param server
 * #
 */
prortc.init = function (userData, token, connection_id) {
    if (prortc.socket) {
        prortc.socket.removeAllListeners();
        prortc.socket = null;
    }
    //   prortc.socket = io.connect('http://localhost:5001/')
    prortc.socket = io.connect(config.SOCKET_URL, {
        autoConnect: true,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionAttempts: 20,
        forceNew: true,
        query: {
            token: token,
            connection_id: connection_id
        },
        transports: ['websocket']
    });
    prortc.connectionId = connection_id
    prortc.token = token
    prortc.socket.on('connect', () => {
        console.log('socket connected ', prortc.socket.id);
        prortc.emit('register', userData);
    });

    prortc.socket.on('reconnect', function (socket) {
        console.log('socket reconnect ', socket);
        prortc.emit('reconnect');
    });

    prortc.socket.on('reconnect_attempt', function (socket) {
        console.log('socket reconnect_attempt ', socket);
        prortc.emit('reconnect_attempt');
    });

    prortc.socket.on('reconnect_error', function (socket) {
        console.log('socket reconnect_error ', socket);
        prortc.emit('reconnect_error');
    });

    prortc.socket.on('reconnect_failed', function (socket) {
        console.log('socket reconnect_failed ', socket);
        prortc.emit('reconnect_failed', socket);
    });

    prortc.socket.on('error', function (error) {
        console.log('socket connection error ', error);
    });

    prortc.socket.on('disconnect', function (reason) {
        prortc.close();
        console.log('socket disconnect', reason);
        prortc.emit('socket_disconnect');
    });

    prortc.socket.on('check_room', function (data) {
        prortc.emit('check_room', data);
    });

    prortc.socket.on('room_message', function (data) {
        prortc.emit('room_message', data);
    });

    prortc.socket.on('get_peers', function (data) {
        log('info', 'get_peers', data);
        prortc.createPeerConnections(data);
    });

    prortc.socket.on('ice_candidate', function (data) {
        let candidate = new RTCIceCandidate({
            sdpMLineIndex: data.label,
            candidate: data.candidate
        });
        log('info', 'ice_candidate', data);
        if (prortc.peerConnections[data.socketId]) {
            prortc.peerConnections[data.socketId].addIceCandidate(candidate);
            prortc.emit('ice_candidate', candidate); // only emit, not in use
        }
    });

    /**
     * #
     */
    prortc.socket.on('new_peer', function (data) {
        log('info', 'new_peer', data);
        prortc.sockets.push(data.socketId);
        prortc.emit('new_peer', data);
        prortc.otherSocketId = data.socketId;
        console.log("remote socket id", data.socketId);
        console.log("local socket id", prortc.socket.id);
        let pc = prortc.createPeerConnection({ id: data.socketId, user: data.joinedUser });

        setTimeout(function () {
            prortc.sendOffers(data);
        }, 2000);
    });

    prortc.socket.on('connected_two_users', function () {
        prortc.emit('connected_two_users');
    });

    prortc.socket.on('remove_peer', function (data) {
        let index = prortc.sockets.indexOf(data.socketId);

        if (index > -1) {
            prortc.sockets.splice(index, 1);
        }

        prortc.peerConnections[data.socketId] && prortc.peerConnections[data.socketId].close();
        delete prortc.peerConnections[data.socketId];
        log('info', 'remote peer connection removed', data);
        log('info', 'disconnect reason', data.reason);
        log('info', 'room lenth', data.totalRoomMember);

        if (data.reason === 'client namespace disconnect') {
            // prortc.endCall();
            prortc.detachStream("remoteVideo");
        } else {
            if (data.user.role == 'invitee') {
                prortc.emit('remove_invitee_stream', data);
            } else {
                switch (data.reason) {
                    case "io server disconnect":
                    case "io client disconnect":
                        prortc.emit('close_Reason', data.reason);
                        break;
                    case "ping timeout":
                        prortc.emit('close_Reason', data.reason);
                        break;
                    case "transport close":
                    case "transport error":
                        prortc.emit('close_Reason', data.reason);
                        break;
                    default:
                        console.log(`remote peer disconnected.`);
                }
            }
            // updateGroupCallView() 
            if (!prortc.isMediaFoundError) {
                prortc.emit('disconnected_peer', data);
            } else {
                prortc.emit('disconnected_peer_wihtout_media', data);
            }
        }
    });

    prortc.socket.on('receive_offer', function (data) {
        log('info', 'receive_offer', data);
        console.log("Issue 1: receive_offer",data);
        prortc.receiveOffer(data.socketId, data.sdp);
        prortc.emit('receive_offer', data);
    });

    prortc.socket.on('receive_answer', function (data) {
        log('info', 'receive_answer', data);
        prortc.receiveAnswer(data.socketId, data.sdp);
        prortc.emit('receive_answer', data);
    });

    prortc.socket.on('room_full', function (data) {
        log('info', 'room_full', data);
        prortc.emit('room_full', data);
    });

    prortc.socket.on('user-online', function (data) {
        console.log("user online prortc");
        prortc.emit('user-online', data);
    });


    prortc.socket.on('receive_event', function (data) {
        console.log("receive_event", data);
        prortc.emit('receive_event', data);
    });

    prortc.socket.on('calling_message_receive', function (data) {
        prortc.emit('calling_message_receive', data);
    });

    prortc.socket.on('chat_request_by_user', function (data) {
        prortc.emit('chat_request_by_user', data);
    });

    prortc.socket.on('new_msg_receive', function (data) {
        prortc.emit('new_msg_receive', data);
    });
    prortc.socket.on('broadcast', function (data) {
        prortc.emit('broadcast', data);
    });

    prortc.socket.on('set_turn_server', function (data) {
        console.log("tern server", data);
        prortc.iceServers = data.iceServers;
    });

    prortc.socket.on('receive_chat_event', function (data) {
        console.log("receive_chat_event", data);
        prortc.emit('receive_chat_event', data);
    });    
};


prortc.on('register', function (data) {
    console.log('register', data);
    prortc.socket.emit('register', data);
    prortc.emit("registered");
});

prortc.on('send_calling_message', function (data) {
    prortc.socket.emit('send_calling_message', data);
});

prortc.on('leave_chat_room', function (data) {
    prortc.socket.emit('leave_chat_room', data);
});

prortc.on('join_chat_room', function (data) {
    prortc.socket.emit('join_chat_room', data);
});

prortc.on('send_message', function (data) {
    prortc.socket.emit('send_message', data);
});

prortc.on('send_chat_event', function (data) {
    prortc.socket.emit('send_chat_event', data);
});

prortc.on('check-user-online', function (data) {
    console.log("check-user-online issue ");
    prortc.socket.emit('check-user-online', data);
});

/**
 * Send offers to all connected sockets
 * #
 */
prortc.sendOffers = function (data) {
    prortc.sendOffer(data.socketId);
    prortc.isConnecting = true;
};

prortc.send_event = function (data) {
    prortc.socket.emit('send_event', data);
};

/**
 * Create all connected user peerconnection
 */
prortc.createPeerConnections = function (data) {
    let connections = data.connections;
    let connectionsLength = connections.length;
    for (let i = 0; i < connectionsLength; i++) {
        let user = connections[i].user;
        if (!prortc.peerConnections[user.socketId]) {
            prortc.sockets.push(user.socketId);
            prortc.createPeerConnection({ id: user.socketId, user });
        }
    }
};

/**
 * Create new peerconnection
 * @param id
 * @returns {Window.RTCPeerConnection|*}
 * #
 */
prortc.createPeerConnection = function (data) {
    log('info', 'create peer connection', data);
    let config = {
        'optional': [{
            'googIPv6': true
        }]
    }
    let id = data.id;
    let pc = prortc.peerConnections[id] = new RTCPeerConnection(prortc.iceServers, config);

    pc.onicecandidate = function (event) {
        if (event.candidate) {
            prortc.socket.emit('ice_candidate', {
                label: event.candidate.sdpMLineIndex,
                id: event.candidate.sdpMid,
                candidate: event.candidate.candidate,
                'socketId': id
            });
        }

        prortc.emit('ice candidate', event.candidate); 
    };

    pc.oniceconnectionstatechange = function (event) {
        log('info', 'ice connection state', pc.iceConnectionState);

        if (pc.iceConnectionState === 'failed') {
            prortc.emit('ice_failed', {
                socketId: id
            });
        }

        if (pc.iceConnectionState === 'disconnected') {
            prortc.emit('disconnected', {
                socketId: id
            });
        }
    };

    pc.onopen = function () {
        prortc.emit('peer connection opened');
    };

    pc.onaddstream = function (event) {
        prortc.remoteStreams[id] = event.stream;
        prortc.otherSocketId = id;
        prortc.attachRemoteStream(event, id, data.user);
        prortc.emit('remote_stream', event.stream, id); 
        prortc.isConnecting = false;
    };
    let stream = prortc.streams[0];
    pc.addStream(stream);
    // stream.getTracks().forEach(track => pc.addTrack(track, stream))
    return pc;
};


/**
 * Return sdp offer constraints
 * @returns {*}
 */
function getOfferConstraints() {
    if (navigator.mozGetUserMedia) {
        return {
            offerToReceiveAudio: true,
            offerToReceiveVideo: true
        };
    } else {
        return {
            optional: [{
                DtlsSrtpKeyAgreement: true
            }],
            mandatory: {
                OfferToReceiveAudio: true,
                OfferToReceiveVideo: true
            }
        };
    }
};

/**
 * Send sdp offer to user
 * @param socketId
 * #
 */
prortc.sendOffer = function (socketId) {
    let pc = prortc.peerConnections[socketId];

    if (pc) {
        pc.createOffer(
            function (session_description) {
                pc.setLocalDescription(session_description);
                log('info', 'send offer', socketId);

                prortc.socket.emit(
                    'send_offer', {
                    'socketId': socketId,
                    'sdp': session_description
                });
            },
            function (error) {
                console.log('offer error', error);
            },
            getOfferConstraints());
    }
};

/**
 * Receive sdp offer from user
 * @param socketId
 * @param sdp
 */
prortc.receiveOffer = function (socketId, sdp) {
    let pc = prortc.peerConnections[socketId];
    console.log("Issue 2 : receive offer function",socketId,sdp);
    if (pc) {
        console.log("Issue 3: peer connection for remote ");
        pc.setRemoteDescription(new RTCSessionDescription(sdp));
        prortc.sendAnswer(socketId);
    }
};

/**
 * Send sdp answer to offerer user
 * @param socketId
 */
prortc.sendAnswer = function (socketId) {
    let pc = prortc.peerConnections[socketId];
    console.log("Issue 4: send answer function", socketId);
    if (pc) {
        pc.createAnswer(
            function (session_description) {
                pc.setLocalDescription(session_description);
                console.log("Issue 5:  emit send answer event to server", socketId);
                prortc.socket.emit(
                    'send_answer', {
                    'socketId': socketId,
                    'sdp': session_description
                });
            },
            function (error) {
                log('error', 'send answer error', error);
            },

            getOfferConstraints());
    }
};

/**
 * Receive sdp answer from user
 * @param socketId
 * @param sdp
 */
prortc.receiveAnswer = function (socketId, sdp) {
    let pc = prortc.peerConnections[socketId];

    if (pc) {
        pc.setRemoteDescription(new RTCSessionDescription(sdp));
    }
};

/**
 * Start new call
 * @param option
 * @param onSuccess
 * @param onFail
 * #
 */
prortc.startCall = function (option, onSuccess, onFail) {
    let options;
    onSuccess = onSuccess || function () { };
    onFail = onFail || function () { };

    prortc.stopLocalStream();

    options = {
        video: !!option.video,
        audio: !!option.audio
    };
    console.log("media stream audio and video option ",options);
    navigator.mediaDevices.getUserMedia(options).then(
        stream => {
            prortc.streams[0] = stream;
            prortc.attachStream(stream, 'localVideo');
            onSuccess(stream);
        },
        error => {
            prortc.isMediaFoundError = true;
            log('error', 'Media error', error);
            switch (error.name) {
                case "NotFoundError":
                case "DevicesNotFoundError":
                    prortc.emit('device_error', error.name);
                    break;
                case "NotReadableError":
                case "TrackStartError":
                    prortc.emit('device_error', error.name);
                    break;
                case "OverconstrainedError":
                case "ConstraintNotSatisfiedError":
                    prortc.emit('device_error', error.name);
                    break;
                case "NotAllowedError":
                case "PermissionDeniedError":
                    prortc.emit('device_error', error.name);
                    break;
                case "TypeError":
                    prortc.emit('device_error', error.name);
                    break;
                default:
                    prortc.emit('device_error', "stream_not_readable");

            }

            onFail(error);
            prortc.emit('media_device_error', error);
        }
    )
};

/**
 * Attach stream to element
 * @param stream
 * @param domId
 * #
 */
prortc.attachStream = function (stream, domId) {
    let element = document.querySelector('#' + domId);
    element.srcObject = stream;
};

prortc.attachRemoteStream = function (event, domId, data) {
    log('info', 'remotestream', event.stream);
    prortc.emit("attach_remote_stream", { event, domId, data });
};

/**
 * Detach stream to element
 * @param domId
 */
prortc.detachStream = function (domId) {
    prortc.emit("detach_remote_stream", domId);
};

/**
 * Set track toggle
 * @param tracks
 */
prortc.setTrackToggle = function (tracks) {
    for (let i = 0; i < tracks.length; i++) {
        tracks[i].enabled = !(tracks[i].enabled);
    }
};

/**
 * Toggle mute video
 */
prortc.muteVideoToggle = function () {
    let tracks = prortc.streams[0].getVideoTracks();
    prortc.setTrackToggle(tracks);
};

/**
 * Toggle mute audio
 */
prortc.muteAudioToggle = function () {
    let tracks = prortc.streams[0].getAudioTracks();
    prortc.setTrackToggle(tracks);
};

/**
 * Stop local stream
 */
prortc.stopLocalStream = function () {
    if (prortc.streams.length > 0) {
        log('info', 'stop local stream', prortc.streams[0]);
        prortc.streams[0].getTracks().forEach(track => {
            track.stop();
        });
        prortc.streams = [];
    }
};

/**
 * Close connections
 */
prortc.close = function () {
    for (let i = prortc.sockets.length - 1; i >= 0; i--) {
        console.log(prortc.sockets[i]);
        //   prortc.detachStream(prortc.sockets[i]);
        let index = prortc.sockets.indexOf(prortc.sockets[i]);
        if (index > -1) {
            log('info', 'delete sockets', prortc.sockets[i]);
            prortc.sockets.splice(index, 1);
        }

        if (prortc.sockets[i] && prortc.peerConnections[prortc.sockets[i]]) {
            prortc.peerConnections[prortc.sockets[i]].close();
            delete prortc.peerConnections[prortc.sockets[i]];
        }
    }
    prortc.stopLocalStream();
    prortc.remoteStreams = {};
    prortc.peerConnections = {};

    log('info', 'close connection');
};

/**
 * End webrtc call
 * #
 */
prortc.endCall = function () {
    prortc.socket.disconnect();
    // prortc.emit("detach_local_stream", domId);
    prortc.stopLocalStream();
    prortc.close();
    prortc.emit('end_call_redirection');
    
};
