import React from 'react'
import PropTypes from 'prop-types'
import { Layout, Popover } from "antd";
import { Link } from 'react-router-dom'
import config from "../config";
import languageData from '../components/header/languageData';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
const {Content, Header, Footer} = Layout;

const UserLayout = (props) => {
  const { children } = props
  const { t } = useTranslation()
  const location = useLocation()

  const languageMenu = () => (
    <div className="popover-lang-scroll">
      <ul className="sub-popover">
      {languageData.map(language =>
          <li className="media pointer" 
            key={JSON.stringify(language)} 
            onClick={() => {
              localStorage.setItem(`${config.NAME_KEY}:language`,language.locale)
              window.location.reload(false);
            }
          }>
            <i className={`flag flag-24 mr-2 flag-${language.icon}`}/>
            <span className="language-text">{t(`language.${language.name}`)}</span>
          </li>
        )}
      </ul>
    </div>);
  return (
    <>
    <Layout className="authLayout clinicLogin">
        <Header className="topHeader authLayout-header">
            <Link to='/'><img className="logo" src="/assets/images/logo.png" alt="logo" /></Link>
            <div className="language">
                <Popover overlayClassName="popover-horizantal" placement="bottomRight" content={languageMenu()}
                        trigger="click">
                    <span className="pointer flex-row align-items-center">
                    <i className={`flag flag-24 flag-${t('common.flag')}`}/>
                        <span className="pl-2 language-name">{t('patients.language')}</span>
                        <i className="icon icon-chevron-down pl-2"/>
                    </span>
                </Popover>
            </div>
        </Header>
        
        {location.pathname.search('invitee') === -1 ?
        <>
        <Content className={`layout-content authLayout-content`}>   
            {children}
        </Content>
        <Footer className="authpage-footer">
            <div className="copyright">
                {config.FOOTER_TEXT}
            </div>
            <ul className="links">
                <li><Link to='#'>FAQ</Link></li>
                <li><Link to='#'>Terms & Conditions</Link></li>
                <li><Link to='#'>Privacy Policy</Link></li>
            </ul>
        </Footer>
        </> :
        <Content className={`layout-content`}>   
          <div className="main-content-wrapper">   
            {children}
            </div> 
        </Content>   
        }
    </Layout>
    </>
  )
}

UserLayout.propTypes = {
  children: PropTypes.any.isRequired
}

export default UserLayout