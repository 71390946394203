const languageData = [
    {
      languageId: 'english',
      locale: 'en',
      name: 'English',
      icon: 'us'
    },  
    // {
    //   languageId: 'hindi',
    //   locale: 'hi',
    //   name: 'Hindi',
    //   icon: 'hi'
    // },  
  
  ];
  export default languageData;
  