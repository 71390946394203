import APIrequest from '../apiRequest'
import ApiEndPoints from '../../utilities/apiEndPoints'
import logger from '../../utilities/logger'

export const addSocketTokenService = async () => {
    const payload = {
      ...ApiEndPoints.addSocketToken,
    }
    try {
      return await APIrequest(payload)
    } catch (error) {
      logger(error)
      throw error
    }
  }

  export const getSocketTokenService = async () => {
    const payload = {
      ...ApiEndPoints.getSocketToken
    }
    try {
      return await APIrequest(payload)
    } catch (error) {
      logger(error)
      throw error
    }
  }

  export const socketAuthenticateService = async (id) => {
    const payload = {
      ...ApiEndPoints.socketAuthenticate(id)
    }
    try {
      return await APIrequest(payload)
    } catch (error) {
      logger(error)
      throw error
    }
  }
