import React, { lazy } from 'react';
import RouteWithLayout from './routeWithLayout'
import { WithAuth } from '../utilities/withAuth'
import MainLayout from '../layouts/main'

const DoctorDashboard = lazy(()=> import('../container/dashboard/doctor'))
const Questionnaire = lazy(()=> import('../container/doctor/questionnaire'))
const Schedule = lazy(()=> import('../container/doctor/schedule'))
const DoctorProfile = lazy(() => import('../container/userProfile/doctorProfile'));
const AddEditDoctor = lazy(() => import('../container/users/doctorList/addEditDoctor'))
const WaitingRoomList = lazy(() => import('../container/doctor/waitingRoom'))
const Messgae = lazy(()=> import('../container/message'))
const Appointment = lazy(()=> import('../container/doctor/appointment'))
const DoctorVideoCall = lazy(()=> import('../container/doctor/doctorVideoCall'))
const ReasonForVisit = lazy(()=> import('../container/doctor/reasonForVisit'))
const ReviewRating = lazy(()=> import('../container/doctor/reviewRating'))
const DoctorSetting = lazy(()=> import('../container/setting'))
const ClinicDetail = lazy(()=> import('../container/doctor/clinicDetail'))
const NotificationList = lazy(()=> import('../container/notification'))
const DoctorTools = lazy(()=> import('../container/clinic/doctorTools'))
const PatientView = lazy(() => import('../container/users/patientList/view/patientView'))
const DischargeList = lazy(() => import('../container/doctor/dischargeSummary'))
const Calender = lazy(()=> import('../container/doctor/calender'))
const Invitee = lazy(()=> import('../container/invitee'))



function DoctorRoutes(props) {
    return (
        <>
            <RouteWithLayout
              component={DoctorDashboard}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/dashboard'
            /> 
            <RouteWithLayout
              component={DoctorProfile}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/profile' 
            /> 
             <RouteWithLayout
              component={AddEditDoctor}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/edit-profile' 
            /> 
            <RouteWithLayout
              component={Questionnaire}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/questionnaire'
            /> 
            <RouteWithLayout
              component={WaitingRoomList}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/waiting-room'
            />
            <RouteWithLayout
              component={Schedule}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/schedule'
            /> 
            <RouteWithLayout
              component={Messgae}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/message'
            /> 
            <RouteWithLayout
              component={Appointment}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/appointment'
            /> 
            <RouteWithLayout
              component={DoctorVideoCall}
              exact
              layout={WithAuth(MainLayout)}
              path='/video-call/:code'
            /> 
            <RouteWithLayout
              component={ReasonForVisit}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/reason-for-visit'
            /> 
             <RouteWithLayout
              component={ReviewRating}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/review-rating'
            /> 
            <RouteWithLayout
              component={DoctorSetting}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/setting' 
            /> 
            <RouteWithLayout
              component={ClinicDetail}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/clinic-list' 
            /> 
            <RouteWithLayout
              component={NotificationList}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/notification' 
            /> 
            <RouteWithLayout
              component={DoctorTools}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/tools'
            />
            <RouteWithLayout
              component={PatientView}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/patient-profile/:id' 
            /> 
            <RouteWithLayout
              component={DischargeList}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/discharge-summary/:appointmentId' 
            /> 
            <RouteWithLayout
              component={Calender}
              exact
              layout={WithAuth(MainLayout)}
              path='/doctor/calender' 
            /> 
        </>
    );
}

export default DoctorRoutes;