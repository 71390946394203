import React, { lazy } from 'react';
import RouteWithLayout from './routeWithLayout'
import { WithAuth } from '../utilities/withAuth'
import MainLayout from '../layouts/main'
import LoginLayout from '../layouts/login'

const ClinicDashboard = lazy(() => import('../container/dashboard/clinic'))
const ClinicResetPassword = lazy(()=> import('../container/resetPassword/resetPasswordClinic'))
const ClinicSetting = lazy(()=> import('../container/setting/'))
const ClinicSurvey = lazy(() => import('../container/clinic/survey/updateSurvey'))
const PatientTools = lazy(() => import('../container/clinic/patientTools'))
const DoctorTools = lazy(()=> import('../container/clinic/doctorTools'))
const ClinicProfile = lazy(() => import('../container/userProfile/clinicProfile'));
const AddEditClinic = lazy(() => import('../container/clinicList/addEditClinic'))
const DoctorList = lazy(() => import('../container/users/doctorList'))
const AddEditDoctor = lazy(() => import('../container/users/doctorList/addEditDoctor'));
const dischargeSummary = lazy(()=>import('../container/dischargeSummary/dischargeList'))
const PatientList = lazy(() => import('../container/users/patientList'))
const DoctorView = lazy(()=> import('../container/users/doctorList/viewDoctor'))
const PatientDetail = lazy(() => import('../container/users/patientList/view/patientView'))
const AddEditPatient = lazy(() => import('../container/users/patientList/addEditPatient'))
const PatientPortal = lazy(()=> import('../container/clinic/patientPortal'))
const DailyAppointment = lazy(()=> import('../container/clinic/dailyAppointment'))
const Messgae = lazy(()=> import('../container/message'))
const Report = lazy(() => import('../container/report'))
const NotificationList = lazy(()=> import('../container/notification'))

function ClinicRoutes(props) {
    return (
        <>
            <RouteWithLayout
              component={ClinicDashboard}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/dashboard'
            />    
            <RouteWithLayout
              component={ClinicResetPassword}
              layout={LoginLayout}
              path='/clinic/reset-password/:token'
              exact
            /> 
            <RouteWithLayout
              component={ClinicSetting}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/setting' 
            />
            <RouteWithLayout
              component={ClinicSurvey}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/update-survey' 
            />
            <RouteWithLayout
              component={PatientTools}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/patient-tools'
            />
            <RouteWithLayout
              component={DoctorTools}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/doctor-tools'
            />
            <RouteWithLayout
              component={ClinicProfile}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/profile' 
            /> 
            <RouteWithLayout
              component={AddEditClinic}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/edit-profile' 
            /> 
            <RouteWithLayout
              component={dischargeSummary}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/dischargesummary'
            />
            <RouteWithLayout
              component={PatientList}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/patient-list'
            />
            <RouteWithLayout
              component={DoctorList}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/doctor-list'
            />
            <RouteWithLayout
              component={AddEditDoctor}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/doctor-add' 
            />
            <RouteWithLayout
              component={AddEditDoctor}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/doctor-edit/:id' 
            />
            <RouteWithLayout
              component={DoctorView}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/view-doctor/:id' 
              />
              <RouteWithLayout
              component={PatientDetail}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/patient-detail/:id'
            />
            <RouteWithLayout
              component={AddEditPatient}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/add-patient'
            />
            <RouteWithLayout
              component={AddEditPatient}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/patient-edit/:id'
            />
            <RouteWithLayout
              component={PatientPortal}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/patient-portal'
            />
            <RouteWithLayout
              component={DailyAppointment}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/daily-appointment'
            />
            <RouteWithLayout
              component={Messgae}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/message'
            /> 
            <RouteWithLayout
              component={Report}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/report' 
            /> 
             <RouteWithLayout
              component={NotificationList}
              exact
              layout={WithAuth(MainLayout)}
              path='/clinic/notification' 
            /> 
        </>
    );
}

export default ClinicRoutes;