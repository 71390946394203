import { createSlice } from '@reduxjs/toolkit'

import { getCountryList,getStateList } from '../../services/country'
import { getNotificationUnreadCount } from '../../services/notification'
import { getMessageUnreadCount } from '../../services/message'
import logger from '../../utilities/logger'
export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    countries: [],
    states: [],
    notificationCount: 0,
    messageCount: 0
  },
  reducers: {
    getCountryListAction: (state, action) => {
      return {
        ...state,
        countries: action.payload
      }
    },

    getStateListAction: (state, action) => {
      return {
        ...state,
        states: action.payload
      }
    },

    getNotificationCountAction: (state, action) => {
      return {
        ...state,
        notificationCount: action.payload
      }
    },

    getMessageCountAction: (state, action) => {
      return {
        ...state,
        messageCount: action.payload
      }
    }
  }
})

export const {
    getCountryListAction,
    getStateListAction,
    getNotificationCountAction,
    getMessageCountAction
} = commonSlice.actions


export const getCountries = () => async (dispatch) => {
  try {
    let res = await getCountryList()
    if (res && res.message) {
        dispatch(getCountryListAction(res.data))
    }
  } catch (error) {
    logger(error)
  }
}

export const getStates = () => async (dispatch) => {
    try {
      let res = await getStateList()
      if (res && res.message) {
          dispatch(getStateListAction(res.data))
      }
    } catch (error) {
      logger(error)
    }
  }

export const getNotificationCount = () => async (dispatch) => {
  try {
    let res = await getNotificationUnreadCount()
    if (res && res.success) {
        dispatch(getNotificationCountAction(res.data.unreadNotificationCount))
        dispatch(getMessageCountAction(res.data.unReadMessageCount))
    }
  } catch (error) {
    logger(error)
  }
}


export const selectCountryList = (state) => state.common.countries

export const selectStateList = (state) => state.common.states

export const selectNotificationCount = (state) => state.common.notificationCount

export const selectMessageCount = (state) => state.common.messageCount


export default commonSlice.reducer
