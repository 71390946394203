export const patientRoutes = (t) => [
      {
        endPoint: '/patient/dashboard',
        authRequire: true,
        addInPatientSideBar: true,
        active: ['/patient/dashboard'],
        title: t('dashboard.title'),
        icon: 'icon icon-widgets',
        userTypes: ['patient'],
        key: 'patientDashboard'
      },
      {
        endPoint: '/patient/profile',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/profile'],
        userTypes: ['patient'],
        key: 'patientProfile'
      },
      {
        endPoint: '/patient/edit-profile',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/edit-profile'],
        userTypes: ['patient'],
        key: 'patientEditProfile'
      },
      {
        endPoint: '/patient/setting',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/setting'],
        userTypes: ['patient'],
        key: 'patientSetting'
      },
      {
        endPoint: '/patient/appointment',
        authRequire: true,
        addInPatientSideBar: true,
        active: ['/patient/appointment'],
        title: t('appointment.myAppointment'),
        icon: 'icon ico-appointments-fill',
        userTypes: ['patient'],
        key: 'myAppointment'
      },
      {
        endPoint: '/patient/doctor-list',
        authRequire: true,
        addInPatientSideBar: true,
        active: ['/patient/doctor-list'],
        title: t('appointment.bookAppointment'),
        icon: 'icon ico-appointments-fill',
        userTypes: ['patient'],
        key: 'doctorList'
      },
      {
        endPoint: '/patient/book-appointment/:id',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/book-appointment/:id'],
        title: t('appointment.bookAppointment'),
        icon: 'icon ico-appointments-fill',
        userTypes: ['patient'],
        key: 'bookAppointment'
      },
      {
        endPoint: '/patient/waiting-room/:code',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/waiting-room/:code'],
        userTypes: ['patient']
      },
      {
        endPoint: '/video-call/:code',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/video-call/:code'],
        userTypes: ['patient']
      },
      {
        endPoint: '/patient/message',
        authRequire: true,
        addInPatientSideBar: true,
        active: ['/patient/message'],
        title: t('common.message'),
        icon: 'icon ico-comment',
        userTypes: ['patient'],
        key: 'patientMessage'
      },
      {
        endPoint: '/patient/message/:id',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/message/:id'],
        title: t('common.message'),
        icon: 'icon ico-comment',
        userTypes: ['patient'],
      },
      {
        endPoint: '/patient/notification',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/notification'],
        title: t('notification.title'),
        icon: 'icon ico-comment',
        userTypes: ['patient'],
      },
      {
        endPoint: '/patient/tools',
        authRequire: true,
        addInPatientSideBar: true,
        active: ['/patient/tools'],
        title: t('clinics.patientTool'),
        icon: 'icon ico-cog',
        userTypes: ['patient'],
        key: 'patientTools'
      },
      {
        endPoint: '/patient/survey',
        authRequire: true,
        addInPatientSideBar: true,
        active: ['/patient/survey'],
        title: t('clinics.survey'),
        icon: 'icon ico-upload',
        userTypes: ['patient'],
        key: 'patientSurvey'
      },
      {
        endPoint: '/patient/doctor-profile/:id',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/doctor-profile/:id'],
        userTypes: ['patient']
      },
      {
        endPoint: '/patient/reschedule-appointment/:id/:appId',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/patient/reschedule-appointment/:id/:appId'],
        userTypes: ['patient']
      },
      
]