import React,{useState} from "react";
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/auth/authSlice";
import { textFormatter } from "../../utilities/common";

const SidebarLogo = ({sidebarCollapsed, clickSidebarCollapsed}) => { 
  const [show] = useState(true)
  const sidebarOpen = (event) => {
    document.getElementsByTagName('body')[0]
        .classList.remove('showSidebar')
    event.preventDefault()
  }
  const userData = useSelector(selectUserData);
  const { userType,clinicName } = userData

  return (
    <>
    <div className="layout-sider-header">
      <div className="linebar">
          <i className="d-flex d-xl-none icon text-white icon-btn icon-close" onClick={(e) => sidebarOpen(e)}></i>
          <i className={`d-none d-xl-flex text-white icon-btn icon icon-${!sidebarCollapsed ? 'menu-unfold' : 'menu-fold'}`}
          onClick={clickSidebarCollapsed}/>
        </div>
    { userType==="patient" ? 
     <div className="patientDetail">
        <h5 className="userName text-truncate">{textFormatter(clinicName)}</h5>
      </div>
     :
      <Link to="/" className="logo">
        <img alt="logo2" src="/assets/images/logo2.png"/>
      </Link>
    }
  </div>
    <div className="sidebar-overlay d-xl-none" onClick={(e) => sidebarOpen(e)}></div>
    </>
  );
};

export default SidebarLogo;