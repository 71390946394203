import React, { useState, useEffect } from 'react'
import { Spin, Select, Avatar, Rate, Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  currency_symbol,
  currentTimeStamp,
  titleCase
} from '../../utilities/common'


export function PhoneNumberComponent({ phoneNumberCountryCode, phoneNumber }) {
  return <>{phoneNumber !== '-' ? `${phoneNumberCountryCode} ${phoneNumber}` : phoneNumber}</>
  // return (
  //   <a
  //     href={`tel: ${phoneNumberCountryCode} ${phoneNumber}`}
  //   >{`${phoneNumberCountryCode} ${phoneNumber}`}</a>
  // )
}
export function StatusStringFormatter({ status }) {
  return <>{titleCase(status)}</>
}

PhoneNumberComponent.propTypes = {
  phoneNumberCountryCode: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired
}

export function CurrencyComponent({ amount,currency }) {
  var currency_symbols = {
    'USD': '$',
    'EUR': '€',
    'CRC': '₡',
    'GBP': '£',
    'ILS': '₪',
    'INR': '₹',
    'JPY': '¥',
    'KRW': '₩',
    'NGN': '₦',
    'PHP': '₱',
    'PLN': 'zł', 
    'PYG': '₲', 
    'THB': '฿', 
    'UAH': '₴', 
    'VND': '₫', 
};

var symbol

if(currency_symbols[currency]!==undefined) {
  symbol = currency_symbols[currency]
}else{
  symbol = currency_symbols['USD']
}
return <>{`${ amount != null ? symbol+' '+amount : '-' }`}</>
}

export function EmailComponent({ emailId }) {
  return <>{`${emailId ? emailId : '-'}`}</>
  // return <a href={`mailto: ${emailId}`}>{`${emailId}`}</a>
}

EmailComponent.propTypes = {
  emailId: PropTypes.string.isRequired
}

export function ImageComponent({ src, ...imgprops }) {
  return (
    <a data-fancybox={`gallry_${currentTimeStamp()}_${src}`} {...imgprops}>
      <img src={src} alt={src} />
    </a>
  )
}

ImageComponent.propTypes = {
  src: PropTypes.string.isRequired,
  imgprops: PropTypes.object
}
export function RelatedCategoryComponent({ row }) {
  return (
    <>
      {row.related_categories.map((rc, index) => (
        <span key={index}>{rc.category.name}</span>
      ))}
    </>
  )

  // row.related_categories.map((rc, index) => {
  //   return
  // })
}

RelatedCategoryComponent.propTypes = {
  row: PropTypes.object.isRequired
}
export function ServiceTypeComponent({ row }) {
  return (
    <>
      {row.type === 'information' ? (
        <span>Accidental Repair</span>
      ) : row.type === 'service' ? (
        <span>Service Request</span>
      ) : (
        '-'
      )}
    </>
  )
}

ServiceTypeComponent.propTypes = {
  row: PropTypes.object.isRequired
}

export function VideoComponent({ src, videoprops, imgSrc }) {
  return (
    <a
      className='fancybox videoThumb'
      data-fancybox={`gallry_${currentTimeStamp()}_${src}`}
      href={src}
    >
      <img src={imgSrc} {...videoprops} alt={src} />
      <span className='icon-video-button play_icon'></span>
    </a>
  )
}

VideoComponent.propTypes = {
  src: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  videoprops: PropTypes.object
}

export function AudioComponent({ src }) {
  return (
    <audio controls>
      <source src={src} />
    </audio>
  )
}
AudioComponent.propTypes = {
  src: PropTypes.string.isRequired
}

AudioComponent.propTypes = {
  src: PropTypes.string.isRequired
}

export function StatusBadgeComponent({ status }) {
  if (!['active', 'inactive'].includes(status)) {
    return (
      <span className={'badge badge-pill align-middle ml-2'}>{status}</span>
    )
  }
  status = status === 'active'
  return (
    <span
      className={`badge badge-pill ${
        status ? 'badge-success' : 'badge-danger'
      } align-middle ml-2`}
    >
      {status ? 'Active' : 'Inactive'}
    </span>
  )
}

StatusBadgeComponent.propTypes = {
  status: PropTypes.string.isRequired
}

export function UsernameComponent({ username }) {
  return (
    <>
      <img alt='mail-icon' src='/images/user-icon.png' />
      <span>{username} </span>
    </>
  )
}

UsernameComponent.propTypes = {
  username: PropTypes.any.isRequired
}
export function NameComponentWithImage({ firstName, lastName, image }) {
  return (
    <>
      <Avatar size={40} src={image} />
      <span>
        {firstName} {lastName}
      </span>
    </>
  )
}

NameComponentWithImage.propTypes = {
  firstName: PropTypes.any.isRequired,
  image: PropTypes.any.isRequired
}
export function NameComponent({ firstName, lastName }) {
  return (
    <>
      <span>
        {firstName} {lastName}
      </span>
    </>
  )
}

NameComponent.propTypes = {
  firstName: PropTypes.any.isRequired
}
export function DriverTransactionStatusComponent({ status }) {
  return <>{status === 'pending' ? 'Pending' : 'Paid'}</>
}

DriverTransactionStatusComponent.propTypes = {
  status: PropTypes.any.isRequired
}

export function FareInputComponent({ fare, isFareSetting }) {
  return (
    <>
      {isFareSetting && <span>{currency_symbol}</span>}
      {/* <span>{fare ? fare : 0}</span> */}
      <Input value={fare ? fare : 0} disabled />
    </>
  )
}
export function KMInputComponent({ km }) {
  return (
    <>
      <Input value={km ? km : 0} disabled />
      <span>KM</span>
      {/* <Input value={fare} defaultValue={fare} /> */}
    </>
  )
}

export function GoToUserDetailPage({ data, id, state = {}, classProps = '' }) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/user-detail/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToUserDetailPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function GoToViewTracksPage({ data, id, state = {}, classProps = '' }) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/view-track/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToViewTracksPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}
export function GoToViewContestPage({ data, id, state = {}, classProps = '' }) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/contest/${id}/participants`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToViewContestPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function GoToViewCollectionTracksPage({
  data,
  id,
  state = {},
  classProps = ''
}) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/view-collection-track/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToViewCollectionTracksPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function GoToViewCollectionVideosPage({
  data,
  id,
  state = {},
  classProps = ''
}) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/view-collection-video/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToViewCollectionVideosPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function GoToVideoDetailPage({ data, id, state = {}, classProps = '' }) {
  return (
    <>
      <Link
        className={classProps}
        to={{
          pathname: `/video-detail/${id}`,
          state: state
        }}
      >
        {data}
      </Link>
    </>
  )
}

GoToVideoDetailPage.propTypes = {
  data: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  classProps: PropTypes.string,
  state: PropTypes.object
}

export function ReportedByCountShow({ data, row, showList }) {
  return (
    <>
      <a
        href='/'
        // className="btn btn-primary btn-sm"
        className='ml-5'
        onClick={(e) => {
          e.preventDefault()
          showList(row)
        }}
      >
        {data}
        {/* Reported by <span className="ml-2 badge badge-light">{data}</span> */}
      </a>
    </>
  )
}

ReportedByCountShow.propTypes = {
  data: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  showList: PropTypes.func.isRequired
}

export function ReadMoreTextShow({ data, type, showMoreText, t }) {
  if ([undefined, null, false].includes(data)) {
    return <></>
  }
  if (data.length < 80) {
    return <>{data}</>
  }

  return (
    <>
      {data.substring(0, 80)}{' '}
      <a
        href='/'
        className='theme-color ml-1'
        onClick={(e) => {
          e.preventDefault()
          showMoreText({ type, data })
        }}
      >
        {' '}
        {t('common.readMore')}...
      </a>
    </>
  )
}

ReadMoreTextShow.propTypes = {
  data: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  showMoreText: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

export function DateComponent({ date }) {
  return <span className="nowrap-space">{date}</span>
}
export const RatingComponent = (props) => {
  // const [ratingValue, setRatingValue] = useState(0)
  // useEffect(() => {
  //   if (props.ratingValue) {
  //     setRatingValue(props.ratingValue)
  //   }
  // })

  return (
    <span>
      <Rate disabled value={props.ratingValue || 0} />
    </span>
  )
}
export function AddressComponent({ pickupAddress, dropAddress }) {
  return (
    <>
      {pickupAddress} {dropAddress}
    </>
  )
}

DateComponent.propTypes = {
  date: PropTypes.string.isRequired
}

export function RideTypeFormatter({ row }) {
  return <>{row.is_pre_booking ? 'Pre Booking' : 'Now'}</>
}
export function IntervalInSec({ time }) {
  return <>{time}</>
}

IntervalInSec.propTypes = {
  time: PropTypes.number.isRequired
}

export function LoadingSpinner() {
  return <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
}

export function GlobalLoader() {
  return (
    <div className='listloader text-center'>
      <div className='Listpreloader'>
        <div className='spinner'>
          <div className='dot1'></div>
          <div className='dot2'></div>
        </div>
      </div>
    </div>
  )
}
// export function ControlledSelect({
//   cell,
//   row,
//   onChange,
//   columnName,
//   moduleKey = ''
// }) {
//   let isEdit = useSelector(getUserPermission(moduleKey))
//   let userGroups = useSelector(selectUserGroups)
//   let userGroupArray = []
//   if (userGroups.length) {
//     userGroups.map((data, index) => {
//       return userGroupArray.push(data.groupKey)
//     })
//   }

//   const [isValue, setIsDefault] = useState(cell)
//   const [isLoading, setIsLoading] = useState(false)

//   useEffect(() => {
//     setIsDefault(cell)
//   }, [cell, row])

//   const resHandleChange = (val) => {
//     setIsLoading(false)
//     setIsDefault(val)
//   }

//   const handleChange = (val) => {
//     setIsLoading(true)
//     onChange(val, row, resHandleChange, columnName)
//   }

//   if (!userGroupArray.includes(cell)) {
//     return cell
//   }
//   return isEdit ? (
//     <Select
//       defaultValue={cell}
//       onChange={handleChange}
//       loading={isLoading}
//       value={isValue}
//     >
//       {userGroups.map((data, index) => {
//         return (
//           <Select.Option
//             key={`${index}_${data.id}`}
//             className='text-capitalize'
//             value={data.groupKey}
//           >
//             {data.name}
//           </Select.Option>
//         )
//       })}
//     </Select>
//   ) : (
//     titleCase(cell)
//   )
// }

// ControlledSelect.propTypes = {
//   cell: PropTypes.string.isRequired,
//   row: PropTypes.object.isRequired,
//   onChange: PropTypes.func.isRequired,
//   columnName: PropTypes.string
// }

// export function ControlledSwitch({
//   cell,
//   row,
//   onChange,
//   columnName,
//   moduleKey = ''
// }) {
//   // console.log(moduleKey)
//   let isEdit = useSelector(getUserPermission(moduleKey))
//   const [isChecked, setIsChecked] = useState(
//     ['active', 'true', 1].includes(cell)
//   )
//   const [isLoading, setIsLoading] = useState(false)

//   useEffect(() => {
//     setIsChecked(['active', 'true', 1, '1'].includes(cell))
//   }, [cell, row])

//   const resHandleChange = (val) => {
//     setIsLoading(false)
//     setIsChecked(['active', 'true', 1].includes(val))
//   }

//   const handleChange = (val) => {
//     setIsLoading(true)
//     onChange(val, row, resHandleChange, columnName)
//   }

//   if (!['active', 'inactive', 'true', 'false', 0, 1, '1', '0'].includes(cell)) {
//     return cell
//   }

//   return (
//     <>
//       {isEdit ? (
//         <Switch
//           defaultChecked={['active', 'true', 1, '1'].includes(cell)}
//           loading={isLoading}
//           checked={isChecked}
//           onChange={handleChange}
//         />
//       ) : (
//         titleCase(cell)
//       )}
//     </>
//   )
// }

// ControlledSwitch.propTypes = {
//   cell: PropTypes.string.isRequired,
//   row: PropTypes.object.isRequired,
//   onChange: PropTypes.func.isRequired,
//   columnName: PropTypes.string
// }

export function WinnerTypesControlledSelect({
  cell,
  row,
  contestData,
  onChange
}) {
  const [statusValue, setStatusValue] = useState(cell)
  const [isLoading, setIsLoading] = useState(false)
  const [isDisabled, setIsDisabled] = useState(
    ['gold', 'silver', 'bronze'].includes(cell)
  )

  useEffect(() => {
    setStatusValue(cell)
    setIsDisabled(['gold', 'silver', 'bronze'].includes(cell))
  }, [cell, row])

  const resHandleChange = (val) => {
    // console.log('val', val);
    setIsLoading(false)
    setStatusValue(val)
    setIsDisabled(true)
  }

  const handleChange = (val) => {
    setIsLoading(true)
    onChange(val, row, contestData, resHandleChange)
  }

  // if (!['gold', 'silver', 'bronze'].includes(cell)) {
  //   return cell
  // }

  return (
    <>
      {' '}
      {cell ? (
        <span className='text-capitalize'>{cell}</span>
      ) : (
        <Select
          defaultValue={cell}
          disabled={isDisabled}
          value={statusValue}
          onChange={handleChange}
          loading={isLoading}
          placeholder='Is Winner'
        >
          {contestData.ContestWinnerTypes.length
            ? contestData.ContestWinnerTypes.map((data, index) => {
                return (
                  <Select.Option
                    key={`${index}_${data.id}`}
                    className='text-capitalize'
                    value={data.id}
                  >
                    <span className='text-capitalize'>{data.winnerType}</span>{' '}
                  </Select.Option>
                )
              })
            : '-'}
        </Select>
      )}
    </>
  )
}

WinnerTypesControlledSelect.propTypes = {
  cell: PropTypes.string.isRequired,
  row: PropTypes.object.isRequired,
  contestData: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
