export const permissionKeys = [
  {
    moduleKey: 'dashboard',
    moduleName: 'Dashboard'
  },
  {
    moduleKey: 'clinics',
    moduleName: 'Clinics'
  },
  {
    moduleKey: 'users',
    moduleName: 'Users'
  },
  {
    moduleKey: 'clinicAdmin',
    moduleName: 'Clinic Admin'
  },
  {
    moduleKey: 'urgentCare',
    moduleName: 'Urgent Care'
  },
  {
    moduleKey: 'clinicDashboard',
    moduleName: 'Clinic Dashboard'
  },
  {
    moduleKey: 'doctorDashboard',
    moduleName: 'Doctor Dashboard'
  },
  {
    moduleKey: 'patientDashboard',
    moduleName: 'Patient Dashboard'
  }
]
