import React from "react";
import {Avatar} from "antd";
import config from "../../config";
import { timeFormatterhmmA1 } from "../../utilities/common";

const NotificationItem = ({notification}) => {
  return (

    <li className="media">
      <Avatar className="size-40 mr-3"
              alt={notification.user.profileImageUrl}
              src={`${notification?.user?.profileImageUrl}`}/>
              
      <div className="media-body align-self-center">
        <p className="fs-sm mb-0">{notification.customFields.message}</p>
      </div>
    </li>
  );
};

export default NotificationItem;
