export const doctorRoutes = (t) => [
    {
        endPoint: '/doctor/dashboard',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/dashboard'],
        title: t('dashboard.title'),
        icon: 'icon icon-widgets',
        userTypes: ['doctor'],
        key: 'doctorDashboard'
      },
      {
        endPoint: '/doctor/calender',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/calender'],
        title: t('doctors.calender'),
        icon: 'icon ico-appointment01',
        userTypes: ['doctor'],
        key: 'calender'
      },
      {
        endPoint: '/doctor/appointment',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/appointment'],
        title: t('appointment.title'),
        icon: 'icon ico-appointments-fill',
        userTypes: ['doctor'],
        key: 'appointment'
      },
      {
        endPoint: '/doctor/profile',
        authRequire: true,
        addInDoctorSideBar: false,
        active: ['/doctor/profile'],
        userTypes: ['doctor']
      },
      {
        endPoint: '/doctor/setting',
        authRequire: true,
        addInDoctorSideBar: false,
        active: ['/doctor/setting'],
        userTypes: ['doctor']
      },
      {
        endPoint: '/doctor/schedule',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/schedule'],
        title: t('doctors.schedule'),
        icon: 'icon ico-timer',
        userTypes: ['doctor'],
        key: 'schedule'
      },
      {
        endPoint: '/doctor/message',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/message'],
        title: t('common.message'),
        icon: 'icon ico-comment',
        userTypes: ['doctor'],
        key: 'doctorMessage'
      },
      {
        endPoint: '/doctor/edit-profile',
        authRequire: true,
        addInDoctorSideBar: false,
        active: ['/doctor/edit-profile'],
        userTypes: ['doctor']
      },
      {
        endPoint: '/doctor/review-rating',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/review-rating'],
        title: t('ratingReview.title'),
        icon: 'icon ico-star-circle',
        userTypes: ['doctor'],
        key: 'ratingReview'
      },
      {
        endPoint: '/doctor/questionnaire',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/questionnaire'],
        title: t('doctors.questionnaire'),
        icon: 'icon ico-questionnaire',
        userTypes: ['doctor'],
        key: 'questionnaire'
      },
      {
        endPoint: '/doctor/reason-for-visit',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/reason-for-visit'],
        title: t('reasonForVisit.title'),
        icon: 'icon ico-notepad',
        userTypes: ['doctor'],
        key: 'reason-for-visit'
      },
      {
        endPoint: '/doctor/waiting-room',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/waiting-room'],
        title: t('doctors.waitingRoom'),
        icon: 'icon ico-wating',
        userTypes: ['doctor'],
        key: 'doctorwaitingRoom'
      },
      {
        endPoint: '/video-call/:code',
        authRequire: true,
        addInDoctorSideBar: false,
        active: ['/video-call/:code'],
        userTypes: ['doctor']
      },
      {
        endPoint: '/doctor/clinic-list',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/clinic-list'],
        title: t('clinics.clinicList'),
        icon: 'icon ico-pharmacy',
        userTypes: ['doctor'],
        key: 'doctorClinicList'
      },
      {
        endPoint: '/doctor/notification',
        authRequire: true,
        addInDoctorSideBar: false,
        active: ['/doctor/notification'],
        title: t('notification.title'),
        icon: 'icon ico-pharmacy',
        userTypes: ['doctor']
      },
      {
        endPoint: '/doctor/tools',
        authRequire: true,
        addInDoctorSideBar: true,
        active: ['/doctor/tools'],
        title: t('clinics.doctorTool'),
        icon: 'icon ico-cog',
        userTypes: ['doctor'],
        key: 'doctorTools'
      },
      {
        endPoint: '/doctor/patient-profile/:id',
        authRequire: true,
        addInPatientSideBar: false,
        active: ['/doctor/patient-profile/:id'],
        userTypes: ['doctor']
      },
      {
        endPoint: '/doctor/discharge-summary/:appointmentId',
        authRequire: true,
        addInDoctorSideBar: false,
        active: ['/doctor/discharge-summary/:appointmentId'],
        title: t('clinics.doctorTool'),
        icon: 'icon ico-cog',
        userTypes: ['doctor'],
      },
      {
        endPoint: '/invitee/:appointmentCode/:userId',
        authRequire: true,
        addInDoctorSideBar: false,
        active: ['/invitee/:appointmentCode/:userId'],
        title: t('clinics.doctorTool'),
        icon: 'icon ico-cog',
        userTypes: ['doctor'],
      },
]
