import { prortc } from './prortc'
import { getSocketTokenService } from "../services/socket"

let currentRoomId = {id: 0};

export const NewRoomId = (value) => {
  currentRoomId.id = value
}


export const socketInitFunction = async (data) => {
    let userData = {
        userId: (data.userType === 'doctor')? data.doctorUserId : (data.userType === 'patient')? data.patientUserId: data.clinicUserId,
        name: data.userName,
        role: data.userType,
        userImage: data.photo
      }
    let res = await getSocketTokenService()
    if(res){
      let { token,connectionId } = res.data
      prortc.init(userData,token,connectionId)
    }
}

export const socketDisconnectFunction = async () => {
     prortc?.socket?.disconnect();
}

export const sendEvent = (data) => { 
  console.log("send_event",data)
  prortc?.socket?.emit('send_event',data)
}

export const receiveEvent = (history) => {
  prortc.on('receive_event',(data) => {    
    console.log("receive_event",data)
    if(data.eventName === 'admitAppointment' && history.location.pathname ===`/patient/waiting-room/${data.description.room}`){  
      history.push(`/video-call/${data.description.room}`)
    } else if(data.eventName === 'newAppointment'){
      console.log("newAppointment rcv event")
      history()
    }
  })
}


//================================Check User Online/Offline==================

export const checkUserOnlineSendEvent = (type) => {
    let doctorData = JSON.parse(localStorage.getItem("doctorList"))
    doctorData !== [] && doctorData?.map(item => prortc?.emit('check-user-online',item))
}

const handler = (data) => { 
    let doctorData = JSON.parse(localStorage.getItem("doctorList"))
    for (let i = 0; i < doctorData.length; i++) {
      if(data.id === doctorData[i].id){  
        doctorData[i] = data;  
          break; 
      }
    }
    localStorage.setItem('doctorList',JSON.stringify(doctorData));
    setTimeout(()=>{
      let docArray = JSON.parse(localStorage.getItem("doctorList"))
      docArray.map(item => {
        if(item.status === 'Active'){
          if(document.getElementById(`status_${item.id}`) !== null){
            document.getElementById(`status_${item.id}`).className = 'active'
          }
          if(document.getElementById(`fav_status_${item.id}`) !== null){
            document.getElementById(`fav_status_${item.id}`).className = 'active'
          }
        } else {
          if(document.getElementById(`status_${item.id}`) !== null){
            document.getElementById(`status_${item.id}`).className = 'away'
          }
          if(document.getElementById(`fav_status_${item.id}`) !== null){
            document.getElementById(`fav_status_${item.id}`).className = 'away'
          }
        }
      return null
      })
    },1000)
}

export const checkUserOnlineReceiveEvent = () => {
  prortc?.socket?.on('user-online',handler)
}

export const checkUserOnlineReceiveEventOFF = () => {
  prortc?.socket?.off('user-online',handler)
}



// Chat functions

// export const getRoomData = async (data) => {
//   let roomdata = []
//   data.map(item => {
//     roomdata.push({
//       userId: item.chatRoom.patientUserId,
//       roomId: item.chatRoom.id
//     })
//   })
//   prortc.emit('join_chat_room',roomdata)
// }

// export const sendMessage = async (data,userdata) => {
//   let res = await getSocketTokenService()
//   if(res){
//     let { token,connectionId } = res.data
//     data.token = token
//     data.connectionId = connectionId
//     data.name = userdata.name
//     data.photo = userdata.photo
//     prortc.emit('send_message',data)
//   } 
// }

// export function receiveMessageEvent(appendData,setCurrentMessage,setShowTyping) {
//   prortc.on('new_msg_receive',function(data) {
//     let senderReceiverData = {
//       name: data.name,
//       photo: data.photo,
//       roomId: data.roomId
//     }
//       if(currentRoomId.id === data.roomId){
//         setShowTyping(false)
//         appendData(data.message,data.type,"rcv-msg",senderReceiverData)
//         if(data.type === 'media'){
//           setCurrentMessage(data.message.fileName)
//         } else {
//           setCurrentMessage(data.message)
//         }
//       } else {
//         let count = document.getElementById(`unread_count_${data.roomId}`).innerHTML !== '' ? 
//         document.getElementById(`unread_count_${data.roomId}`).innerHTML : 0
//         document.getElementById(`unread_count_${data.roomId}`).innerHTML = parseInt(count) + 1
//         if(document.getElementById(`unread_count_${data.roomId}`).innerHTML > 0){
//           document.querySelector(`#unread_count_${data.roomId}`).className = 'msgCound';
//         }
//       }
//   })
// }


// export const sendChatEvent = (data) => { 
//   prortc.emit('send_chat_event',data)
// }

// export const receiveChatEvent = (setShowTyping) => {
//   prortc.on('receive_chat_event',(data) => {
//     console.log("room id typingEvent",currentRoomId,data.roomId)
//     if(data.eventName === 'typingEvent'){
//       if(currentRoomId.id === data.roomId){
//         setShowTyping(data.userName)
//       } else {
//         setShowTyping('')
//       }
//       console.log("receive_chat_event",data)
//     }
//   })
// }