// import React from 'react'
// import { useTranslation } from 'react-i18next'
// import { Link } from 'react-router-dom'
// import { withRouter } from 'react-router'
// import { useSelector } from 'react-redux'
// import { routesJSON } from '../../utilities/sidebar'
// // import { selectUserData } from '../../redux/auth/authSlice'
// import { permissionKeys } from '../../utilities/permission'
// import { currentTimeStamp, tagClassToggle } from '../../utilities/common'
// // import { Menu, Dropdown } from 'antd'
// // import MenuData from './menuData'
// function SideBar({ match }) {
//   const { t } = useTranslation()
//   // const dispatch = useDispatch()
//   const userType = useSelector((state) => state.auth.userData.userType)
//   // const userData = useSelector(selectUserData)
//   const path = match.path
//   // const pathURL = match.url
//   //   const [show, setShow] = useState(true)
//   const filterOpen = (event) => {
//     document
//       .getElementsByTagName('body')[0]
//       .classList.add('page-sidebar-closed')
//     event.preventDefault()
//   }
//   const toggleDrawerMenu = () => {
//     // console.log('toggleDrawerMenu called ')
//     const element = document.getElementsByTagName('body')[0]
//     if (element.classList.contains('open-menu')) {
//       tagClassToggle('body', 'open-menu')
//     }
//   }

//   return (
//     <div className='side_menu' data-mcs-theme='dark' id='sidemenu'>
//       <div className='navbar_header'>
//         <Link
//           className='close-icon d-sm-none'
//           id='closeToggle'
//           to='/'
//           onClick={(e) => filterOpen(e)}
//         >
//           <i className='ti-close'></i>
//         </Link>
//         <Link to='/dashboard' className='logo'>
//           <img src='assets/images/logo.png' className='img-fluid' alt='logo' />
//         </Link>
//       </div>
//       <ul className='list-unstyled menu' data-mcs-theme='light'>
//         {routesJSON(t, permissionKeys).map((data, index) => {
//           if (data.userTypes.includes(userType) && data.addInSideBar) {
//             if (data.child && data.child.length > 0) {
//               return (
//                 <li
//                   key={index}
//                   className={`${data.active.includes(path) ? 'showMenu' : ''}`}
//                 >
//                   <Link
//                     to='#'
//                     className={`nav-link nav__link ripple-effect subMenu
//                      ${data.active.includes(path) ? '' : 'collapse'}
//                     `}
//                     data-toggle='collapse'
//                     data-target={`#${data.endPoint}`}
//                     role='button'
//                     aria-expanded='false'
//                     aria-controls={data.endPoint}
//                   >
//                     <span className='nav_icon'>
//                       <i className={data.icon}></i>
//                     </span>
//                     <span className='nav_title'>{data.title}</span>
//                   </Link>
//                   <ul
//                     className={`collapse list-unstyled  in-collapse  ${
//                       data.active.includes(path) ? 'show' : ''
//                     }`}
//                     // class='collapse'
//                     id={data.endPoint}
//                   >
//                     {data.child.map((childData, childId) => {
//                       if (
//                         childData.userTypes.includes(userType) &&
//                         childData.addInSideBar
//                       ) {
//                         return (
//                           <li
//                             key={`${currentTimeStamp()}_${index}_${childId}`}
//                             className={
//                               childData.active.includes(path) ? 'active' : ''
//                             }
//                           >
//                             <ul>
//                               <li>
//                                 <Link
//                                   className='nav-link'
//                                   to={childData.endPoint}
//                                   onClick={(e) => {
//                                     toggleDrawerMenu()
//                                   }}
//                                 >
//                                   <span className='nav__title'>
//                                     {childData.title}
//                                   </span>
//                                 </Link>
//                               </li>
//                             </ul>
//                           </li>
//                         )
//                       } else {
//                         return null
//                       }
//                     })}
//                   </ul>
//                 </li>
//               )
//             } else {
//               if (userType === 'admin') {
//                 // console.log('data.active', data.active)
//                 return (
//                   <li
//                     key={`${currentTimeStamp()}_${index}`}
//                     className={data.active.includes(path) ? 'active' : ''}
//                   >
//                     <Link
//                       className='nav-link nav__link ripple-effect'
//                       to={data.endPoint}
//                       onClick={(e) => {
//                         toggleDrawerMenu()
//                       }}
//                     >
//                       <span className='nav__icon'>
//                         <i className={data.icon}></i>
//                       </span>
//                       <span className='nav__title'>{data.title}</span>
//                     </Link>
//                   </li>
//                 )
//               } else {
//                 if (permissionKeys) {
//                   let moduleKeyIndex = permissionKeys.findIndex((item) => {
//                     return item.moduleKey === data.moduleKey
//                   })
//                   if (moduleKeyIndex > -1) {
//                     return (
//                       <li
//                         key={`${currentTimeStamp()}_${index}`}
//                         className={data.active.includes(path) ? 'active' : ''}
//                       >
//                         <Link
//                           className='nav-link nav__link ripple-effect'
//                           to={data.endPoint}
//                           onClick={(e) => {
//                             toggleDrawerMenu()
//                           }}
//                         >
//                           <span className='nav__icon'>
//                             <i className={data.icon}></i>
//                           </span>
//                           <span className='nav__title'>{data.title}</span>
//                         </Link>
//                       </li>
//                     )
//                   } else {
//                     return null
//                   }
//                 } else {
//                   return null
//                 }
//               }
//             }
//           } else {
//             return null
//           }
//         })}
//       </ul>
//     </div>
//   )
// }

// export default withRouter(SideBar)



import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Drawer, Layout} from "antd";
import SidebarContent from "./sidebarContent";
// import {toggleCollapsedSideNav, updateWindowWidth} from "appRedux/actions/Setting";

// import {
//   NAV_STYLE_DRAWER,
//   TAB_SIZE,
//   } from "../../utilities/themeSetting";

const {Sider} = Layout;

const Sidebar = () => {

  const dispatch = useDispatch();
  let [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  // const {navStyle} = useSelector(({settings}) => settings);
  // const {navCollapsed, width} = useSelector(({common}) => common);

  const clickSidebarCollapsed = () => {
    setSidebarCollapsed(!sidebarCollapsed)
  }


  const onToggleCollapsedNav = () => {
    // dispatch(toggleCollapsedSideNav(!navCollapsed));
  };
                                 
                        

  useEffect(() => {
    window.addEventListener('resize', () => {
      // dispatch(updateWindowWidth(window.innerWidth));
    })                                                   
  }, [dispatch]);  
                                          
  return (
    <Sider
      className="app-sidebar"
      trigger={null}
      collapsed={sidebarCollapsed}
      theme={"dark"}
      collapsible>
      {/* {
        navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? */}
          <Drawer
            className="drawer-sidebar"
            placement="left"
            closable={false}
            onClose={onToggleCollapsedNav}
            // visible={navCollapsed}
            bodyStyle={{ padding:0}}
            >
            <SidebarContent sidebarCollapsed={sidebarCollapsed} clickSidebarCollapsed={clickSidebarCollapsed}/>
          </Drawer> 
          {/* : */}
          <SidebarContent sidebarCollapsed={sidebarCollapsed} clickSidebarCollapsed={clickSidebarCollapsed}/>
      {/* } */}
    </Sider>)
};
export default Sidebar;

