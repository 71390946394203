import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Switch,Select } from 'antd'
import _ from 'lodash'
import querystring from 'querystring'
import {
  PhoneNumberComponent,
  ImageComponent,
  CurrencyComponent,
  EmailComponent,
  AudioComponent,
  VideoComponent,
  DateComponent,
  GoToUserDetailPage,
  ReadMoreTextShow,
  GoToViewTracksPage,
  GoToViewContestPage,
  GoToVideoDetailPage,
  ReportedByCountShow,
  IntervalInSec,
  GoToViewCollectionTracksPage,
  GoToViewCollectionVideosPage,
  WinnerTypesControlledSelect,
  StatusStringFormatter,
  NameComponentWithImage,
  NameComponent,
  AddressComponent,
  RatingComponent,
  RelatedCategoryComponent,
  ServiceTypeComponent,
  DriverTransactionStatusComponent
} from '../components/common'
import config from '../config'
import logger from './logger'
import { countryDialCode } from './countryCode'

moment.updateLocale('en', {
  weekdaysMin : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
});

const {Option} = Select

export const currency_symbol = '₹'

export const removeSessionStorageToken = () => {
  sessionStorage.removeItem(`${config.NAME_KEY}:token`)
  window.location.href = '/'
}

export const getLocalStorageLanguage = () => {
  const language = localStorage.getItem(`${config.NAME_KEY}:language`)
  if (language) {
    return ['en', 'hi'].includes(language) ? language : config.DEFAULT_LANGUAGE
  }
  return config.DEFAULT_LANGUAGE
}

/**
 * Toggle Class By Tag First Find
 */

export const classToggle = (tag, className) => {
  const element = document.getElementsByClassName(tag)[0]

  if (element) {
    if (element.classList) {
      element.classList.toggle(className)
    } else {
      const classes = element.className.split(' ')
      const i = classes.indexOf(className)

      if (i >= 0) classes.splice(i, 1)
      else classes.push(className)
      element.className = classes.join(' ')
    }
  }
}

/**
 * Date Time
 */
export const dateFormatDMY = 'DD-MM-YYYY'
export const PHONE_NUMBER_COUNTRY_CODE = '+65'
export const PHONE_NUMBER_COUNTRY = 'SG'
export const DEFAULT_CURRENCY_SYMBOL = '₹'

export const showDateMonthlyFormat = (data) => {
  return moment(data).format('MMM d, YYYY')
}

export const currentDateTime = () => {
  return moment().format('YYYY-MM-DD HH:mm')
}

export const toSendDateInApi = (data) => {
  return moment(data).format('YYYY-MM-DD')
}

export const dateFormatterDDMMYYYY = (data) => {
  return moment(data).format('DD/MM/YYYY')
}

export const DateFormatterMMMMDoYYYY = (data) => {
  return moment(data).format('MMMM Do YYYY')
}

export const DateFormatterMMMMDYYYY = (data) => {
  return moment(data).format('MMMM D YYYY')
}

export const DateFormatterddddMMMMDYYYY = (data) => {
  return moment(data).format('dddd, MMMM, D, YYYY')
}

export const currentTimeStamp = () => new Date().getTime()

export const timeFormatterhmmA = (param) =>{
  return moment(param, "HH:mm").format("h:mm A")
}

export const timeFormatterHH = (param) =>{
  return moment(param, "HH").format("HH")
}

export const timeFormattermm = (param) =>{
  return moment(param, "mm").format("mm")
}

export const timeFormatterHHmm = (param) =>{
    return moment(param, "HH:mm").format("HH:mm")
}

export const timeFormatterhmmA1 = (param) =>{
return moment(param).format("h:mm A")
}

export const timeFormatter12to24 = (param) =>{
return moment(param,'hh:mm A').format('HH:mm')
}

export const DateFormatterDDMMMYYYY = (param) =>{
return moment(param).format('DD MMM YYYY')
}

export const timeFormatterMomentHHmm = (param) =>{
  return moment(param, 'HH:mm')
}

export const timeFormatterHHmmss = (param) =>{
  return moment(param, "HH:mm").format("HH:mm:ss")
}

export const timeFormatterHHmm2 = (param) =>{
  return moment(param, "HH:mm:ss").format("HH:mm")
}

export const timeFormatterMomenthhmmA = (param)=>{
return moment(param, 'hh:mm A')
}

export const monthFormatter = (param) =>{
  return moment(param).format("MMMM")
}

export const yearFormatter = (param) =>{
  return moment(param).format("YYYY")
}

export const dayShortFormatter = (param) =>{
  return moment(param).format("ddd")
}

export const dateShortFormatter = (param) =>{
  return moment(param).format("D")
}

export const dateFormatterMMMMDYY = (param) =>{
  return moment(param).format("MMMM D YY")
}

export const dateFormatterMomentYYYYMMDD = (param) =>{
 return moment(param, 'YYYY-MM-DD')
}

export const timeFormatterHHmm1 = (param) =>{
  return moment(param).format("HH:mm")
}

export const timeFormatterUTCTOLocalDateTimehmmA = (param) =>{
  return moment.utc(param).local().format('YYYY-MMM-DD h:mm A')
}

export const timeFormatterUTCTOLocalDateTimeHHmm = (param) =>{
  return moment.utc(param).local().format('YYYY-MM-DD HH:mm:ss')
}

export const timeFormatterUTCTOLocalDateDDMMMYYYY = (param) =>{
  return moment.utc(param).local().format('DD MMM YYYY')
}

export const timeFormatterMomentUTCTOLocalDateTimeHHmm = (param) =>{
  return moment.utc(param).local()
}

export const timeFormatterUTCTOLocalTimehmmA = (param) =>{
  return moment.utc(param).local().format('h:mm A')
}

export const timeFormatterUTCTOLocalDate = (param) =>{
  return moment.utc(param).local().format('YYYY-MM-DD')
}

export const dateTimeFormatter = (param,format) =>{
  return moment(param).format(format)
}

export const dateTimeFormatterUTCTOLocal = (param,format) =>{
  return moment.utc(param).local().format(format)
}

export const dateTimeFormatterMoment = (param) => {
  return moment(param)
}

export const timeFormatterUTCTOLocalTimeHHmm = (param) =>{
  return moment.utc(param).local().format('HH:mm')
}

export const dateValidationForDOB = (param) =>{
  return moment().subtract(param, "days")
}

export const timeDifference = (startTine,currentTime) => {
  return (
    timeFormatterUTCTOLocalDate(startTine) === toSendDateInApi(currentTime) && 
    moment.duration(timeFormatterMomentHHmm(timeFormatterMomentUTCTOLocalDateTimeHHmm(startTine).subtract(15, 'minutes')).diff(currentTime))._milliseconds
  )
}

// Go back
export const goBack = () =>{
  window.history.back();
}







/**
 * To convert base64 to svg
 * @param {*} srcURL
 */

export const getBase64OfsvgURL = (srcURL) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = srcURL
    img.crossOrigin = 'Anonymous'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.height = img.naturalHeight
      canvas.width = img.naturalWidth
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL()
      resolve(dataURL)
    }
    img.onerror = (error) => reject(error)
  })
}

/**
 * Get query for the datatable
 * page
 * perpage
 */

/**
 * User Role display
 */
export const userRoleName = (role) => {
  let roleName = '-'
  switch (role) {
    case 'businessadmin':
      roleName = 'ADMIN'
      break
    case 'vendor':
      roleName = 'VENDOR'
      break
    case 'customer':
      roleName = 'CUSTOMER'
      break

    default:
      break
  }
  return roleName
}

////////////////////////////////////////////////////////////////////////

export const setSessionStorageToken = (token) => {
  // sessionStorage.setItem(
  //   `${config.NAME_KEY}:token`,
  //   CryptoJS.AES.encrypt(token, `${config.NAME_KEY}-token`).toString()
  // )
  sessionStorage.setItem(`${config.NAME_KEY}:token`, token)
}

// export const handlePrizeTitileChange = async (val) => {

//   // try {
//   let result = 0;
//   const payload = {
//     ...ApiEndPoints.getShopifyProducts,
//     queryParams: {
//       title: val
//     }
//   }
//   const res = await APIrequest(payload);

//   result = (res.data.length) ? res.data[0].id : 0;
//   return result;
//   // } catch (error) {
//   //   logger({ 'error:': error })
//   // }
// }


export const checkUserPermission = (userData, moduleKey) => {
  let isEdit = false
  isEdit = userData.userType === 'admin' ? true : false
  if (userData.permissions) {
    let moduleKeyObj = userData.permissions.find((item) => {
      return item.moduleKey === moduleKey
    })
    if (moduleKeyObj && moduleKeyObj.permission === 'edit') {
      isEdit = true
    }
  }
  return isEdit
}

export const getSessionStorageToken = () => {
  const cipherText = sessionStorage.getItem(`${config.NAME_KEY}:token`)
  // if (ciphertext) {
  //   const bytes = CryptoJS.AES.decrypt(ciphertext, `${config.NAME_KEY}-token`)
  //   return bytes.toString(CryptoJS.enc.Utf8)
  // }
  if (cipherText) {
    return cipherText
  }
  return false
}

// export const removeSessionStorageToken = () => {
//   sessionStorage.removeItem(`${config.NAME_KEY}:token`)
//   window.location.href = '/'
// }

// export const getLocalStorageLanguage = () => {
//   const language = localStorage.getItem(`${config.NAME_KEY}:language`)
//   if (language) {
//     return ['en', 'hi'].includes(language) ? language : config.DEFAULT_LANGUAGE
//   }
//   return config.DEFAULT_LANGUAGE
// }

/**
 * Formatter for Data Tables
 */
export const phoneNumberFormatter = (cell, row) => {
  // console.log('row', row)
  return (
    <PhoneNumberComponent
      phoneNumberCountryCode={
        row.officeCountryCode ? '+'+row.officeCountryCode : ''
      }
      phoneNumber={row.officePhoneNumber ? row.officePhoneNumber : '-'}
    />
  )
}

export const alternateNumberFormatter = (cell, row) => {
  // console.log('row', row)
  return (
    <PhoneNumberComponent
      phoneNumberCountryCode={
        row.alternateCountryCode ? '+'+row.alternateCountryCode : ''
      }
      phoneNumber={row.alternateNumber ? row.alternateNumber : '-'}
    />
  )
}

export const faxNumberFormatter = (cell, row) => {
  // console.log('row', row)
  return (
    <PhoneNumberComponent
      phoneNumberCountryCode={
        row?.faxCountryCode ? '+'+row?.faxCountryCode : ''
      }
      phoneNumber={row.faxNumber ? row.faxNumber : '-'}
    />
  )
}

export const userPhoneNumberFormatter = (cell, row) => {
  return (
    <PhoneNumberComponent
       phoneNumberCountryCode={
        row?.user?.countryCode ? '+'+row?.user?.countryCode : ''
      }
      phoneNumber={row?.user?.phoneNumber ? row?.user?.phoneNumber : '-'}
    />
  )
}

export const statusStringFormatter = (cell, row) => {
  return <StatusStringFormatter status={row.status} />
}

export const emailFormatter = (cell, row) => {
  return <EmailComponent emailId={cell ? cell : ''} />
}

export const textFormatter = (data) => {
  return `${data ? data?.charAt(0)?.toUpperCase() + data.slice(1) : '-'}`
}

export const currencyFormatter = (data,symbol) => {
  return <CurrencyComponent amount={data >= 0 ? data : ''} currency={symbol ? symbol : ''} />
}

export const imageFormatter = (cell, row) => {
  return (
    <div className='user_img'>
      <ImageComponent src={cell} />
    </div>
  )
}
export const relatedCategoryFormatter = (cell, row) => {
  return (
    <div>
      <RelatedCategoryComponent row={row} />
    </div>
  )
}
export const serviceTypeFormatter = (cell, row) => {
  return (
    <div>
      <ServiceTypeComponent row={row} />
    </div>
  )
}

export const videoFormatter = (cell, row) => {
  return (
    <div className='user_img'>
      <VideoComponent src={cell} imgSrc={row.mediaFileThumbUrl} />
    </div>
  )
}

export const audioFormatter = (cell, row) => {
  return <AudioComponent src={cell} />
}

export const statusFormatterActive = (
  cell,
  row,
  onChange,
  columnName,
  moduleKey = ''
) => {
  return ['active', 'inactive', 'true', 'false', 0, 1, '1', '0', 'clinic'].includes(
    cell
  ) ? (
    <ControlledSwitch
      cell={cell}
      row={row}
      onChange={onChange}
      columnName={columnName}
      moduleKey={moduleKey}
    />
  ) : (
    cell
  )
}

export const winnerTypeStatusFormatter = (cell, row, contestData, onChange) => {
  return ['gold', 'silver', 'bronze'].includes(cell) ? (
    <span className='text-capitalize'>{cell}</span>
  ) : (
    <WinnerTypesControlledSelect
      cell={cell}
      row={row}
      contestData={contestData}
      onChange={onChange}
    />
  )
}

// export const zinglerStatusFormatter = (
//   cell,
//   row,
//   onChange,
//   columnName,
//   moduleKey = ''
// ) => {
//   return (
//     <ControlledSelect
//       cell={cell}
//       row={row}
//       onChange={onChange}
//       columnName={columnName}
//       moduleKey={moduleKey}
//     />
//   )
// }

export const dateFormatter = (cell, row) => {
  return <DateComponent date={showDateInBrowser(cell)} />
}

export const ratingFormatter = (cell, row) => {
  return <RatingComponent ratingValue={row.customer_rating} />
}
export const addressFormatter = (cell, row) => {
  return (
    <AddressComponent
      pickupAddress={row.pick_up_address}
      dropAddress={row.end_address ? row.end_address : row.drop_off_address}
    />
  )
}

export const statusFormatter = (
  cell,
  row,
  onChange,
  columnName,
  moduleKey = ''
) => {
  return [
    'active',
    'pending',
    'inactive',
    'true',
    'false',
    0,
    1,
    '1',
    '0'
  ].includes(cell) ? (
    <ControlledSwitch
      cell={cell}
      row={row}
      onChange={onChange}
      columnName={columnName}
      moduleKey={moduleKey}
    />
  ) : (
    cell
  )
}


export const OnlyDateFormatter = (cell, row) => {
  return <DateComponent date={showDateOnlyInBrowser(cell)} />
}

export const intervalFormatter = (cell, row) => {
  return <IntervalInSec time={Number(cell)} />
}

export const goToUserDetail = (cell, row, id, state = {}, classProps = '') => {
  return (
    <GoToUserDetailPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const readMoreText = (cell, row, type, showMoreText, t) => {
  return (
    <ReadMoreTextShow
      data={cell}
      type={type}
      showMoreText={showMoreText}
      t={t}
    />
  )
}

export const serialNumberFormatter = (rowIndex, currentPage, dataPerPage) => {
  return rowIndex + dataPerPage * (currentPage - 1) + 1 || rowIndex
}

export const goToViewTracks = (cell, row, id, state = {}, classProps = '') => {
  return (
    <GoToViewTracksPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}
export const goToViewContest = (cell, row, id, state = {}, classProps = '') => {
  return (
    <GoToViewContestPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const goToViewCollectionTracks = (
  cell,
  row,
  id,
  state = {},
  classProps = ''
) => {
  return (
    <GoToViewCollectionTracksPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}
export const customerNameFormatter = (cell, row) => {
  return row?.customer ? (
    <NameComponent
      firstName={
        row?.customer?.first_name
          ? row?.customer?.first_name
          : row?.customer?.last_name
            ? ''
            : '-'
      }
      lastName={row?.customer?.last_name ? row?.customer?.last_name : ''}
    />
  ) : (
    <NameComponent
      firstName={row?.first_name ? row?.first_name : row?.last_name ? '' : '-'}
      lastName={row?.last_name ? row?.last_name : ''}
    />
  )
}
export const driverNameFormatter = (cell, row) => {
  return (
    <NameComponent
      firstName={
        row?.driver?.first_name
          ? row?.driver?.first_name
          : row?.driver?.last_name
            ? ''
            : '-'
      }
      lastName={row?.driver?.last_name ? row?.driver?.last_name : ''}
    />
  )
}
export const driverTransactionStatusFormatter = (cell, row) => {
  return <DriverTransactionStatusComponent status={row.status} />
}

export const nameWithImageFormatter = (cell, row) => {
  return (
    <NameComponentWithImage
      firstName={row.first_name}
      lastName={row.last_name}
      image={row.photo}
    />
  )
}

export const stringFormatter = (cell, row) => {
  return <StatusStringFormatter status={cell} />
}

export const underScoreFormatter = (cell, row) => {
  return <> {_.startCase(row.discount_type)} </>
}

export const discountTypeFormatter = (data) => {
  return <> {_.startCase(data)} </>
}

export function ControlledSwitch({
  cell,
  row,
  onChange,
  columnName,
  moduleKey = ''
}) {
  // let isEdit = useSelector(getUserPermission(moduleKey));
  const [isChecked, setIsChecked] = useState(
    ['active', 'true', true, 1].includes(cell)
  )
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsChecked(['active', 'true', 1, true].includes(cell))
  }, [cell, row])

  const resHandleChange = (val) => {
    setIsLoading(false)
    setIsChecked(['active', 'true', 1, true].includes(val))
  }

  const handleChange = (val) => {
    setIsLoading(true)
    onChange(val, row, resHandleChange, columnName)
  }

  if (
    ![
      'active',
      'inactive',
      'deleted',
      'true',
      'false',
      'pending',
      true,
      false,
      0,
      1
    ].includes(cell)
  ) {
    return cell
  }

  return (
    <>
      {true ? (
        <Switch
          defaultChecked={['active', 'true', 1, true].includes(cell)}
          loading={isLoading}
          checked={isChecked}
          onChange={handleChange}
        />
      ) : (
        titleCase(cell)
      )}
    </>
  )
}

// ControlledSwitch.propTypes = {
//   cell: PropTypes.any.isRequired,
//   row: PropTypes.object.isRequired,
//   onChange: PropTypes.func.isRequired,
//   columnName: PropTypes.string
// }

export const goToViewCollectionVideos = (
  cell,
  row,
  id,
  state = {},
  classProps = ''
) => {
  return (
    <GoToViewCollectionVideosPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const goToVideoDetail = (cell, row, id, state = {}, classProps = '') => {
  return (
    <GoToVideoDetailPage
      data={cell}
      id={id}
      state={state}
      classProps={classProps}
    />
  )
}

export const reportedByCount = (cell, row, showList) => {
  return <ReportedByCountShow data={cell} row={row} showList={showList} />
}

/**
 * Toggle Class
 */

export const tagClassToggle = (tag, className) => {
  const element = document.getElementsByTagName(tag)[0]

  if (element.classList) {
    element.classList.toggle(className)
  } else {
    const classes = element.className.split(' ')
    const i = classes.indexOf(className)

    if (i >= 0) classes.splice(i, 1)
    else classes.push(className)
    element.className = classes.join(' ')
  }
}

/**
 * Date Picker Range class Add
 */

export const onOpenDateRange = (status) => {
  if (status) {
    // setTimeout(() => {
    //   const element = document.getElementsByClassName('ant-picker-date-range-wrapper')
    //   Array.prototype.filter.call(element, (testElement) => {
    //     testElement.parentNode.parentNode.parentNode.classList.add('antDatepiker')
    //     testElement.parentNode.parentNode.parentNode.style.position = 'fixed'
    //     testElement.parentNode.parentNode.parentNode.style.zIndex = '999'
    //   })
    // }, 1000)
  }
}

/**
 * Date Time
 */

export const timeExtracter = (data) => {
  if (moment(moment(data).format('LT'), 'LT', true).isValid()) {
    return moment(data).format('LT')
  } else {
    return '-'
  }
}

export const showDateOnlyInBrowser = (data) => {
  if (moment(moment(data).format('ll'), 'll', true).isValid()) {
    return moment(data).format('ll')
  } else {
    return '-'
  }
}
export const showDateInBrowser = (data) => {
  return moment(data).format('ll, hh.mm a')
}

export const twentyFourHourFormatter = (cell, row) => {
  return <DateComponent date={showTwentyFourFormat(cell)} />
}

export const showTwentyFourFormat = (data) => {
  if (moment(data).isValid()) {
    return moment(data).format('H:mm')
  } else {
    return '-'
  }
}
export const showDateWithTwentyFourFormat = (data) => {
  if (moment(data).isValid()) {
    return moment(data).format('ll,H:mm')
  } else {
    return '-'
  }
}

export const twentyFourHourBookingFormatter = (cell, row) => {
  return <DateComponent date={showTwentyFourBookingFormat(cell)} />
}

export const showTwentyFourBookingFormat = (data) => {
  if (moment(data).isValid()) {
    return moment(data).format('ll,H:mm')
  } else {
    return '-'
  }
}

export const showTimeOnlyInBrowser = (data) => {
  if (moment(moment(data).format('LT'), 'LT', true).isValid()) {
    return moment(data).format('LT')
  } else {
    return '-'
  }
}

/**
 * Dashboard Number Format
 */

export const numberFormatter = (num) => {
  num = Number(num)
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  }
  return num || 0
}

/**
 * File Size
 */

export const fileSizeLimitCheck = (fileSize, fileType) => {
  const res = {
    success: true
  }

  fileSize = fileSize / (1024 * 1000) // In MB

  if (fileType === 'image') {
    if (fileSize > config.IMAGE_UPLOAD_SIZE_LIMIT) {
      res.success = false
      res.limit = config.IMAGE_UPLOAD_SIZE_LIMIT
    }
  } else if (fileType === 'audio') {
    if (fileSize > config.AUDIO_UPLOAD_SIZE_LIMIT) {
      res.success = false
      res.limit = config.AUDIO_UPLOAD_SIZE_LIMIT
    }
  } else if (fileType === 'video') {
    if (fileSize > config.VIDEO_UPLOAD_SIZE_LIMIT) {
      res.success = false
      res.limit = config.VIDEO_UPLOAD_SIZE_LIMIT
    }
  } else if (fileType === 'file') {
    if (fileSize > config.FILE_UPLOAD_SIZE_LIMIT) {
      res.success = false
      res.limit = config.FILE_UPLOAD_SIZE_LIMIT
    }
  } else if (fileType === 'media') {
    if (fileSize > config.FILE_UPLOAD_SIZE_LIMIT) {
      res.success = false
      res.limit = config.FILE_UPLOAD_SIZE_LIMIT
    }
  }else {
    res.success = false
    res.limit = 100
  }

  return res
}

/**
 * Audio File Length
 */

export const checkAudioFileLength = (length) => {
  const res = {
    success: true
  }

  if (
    length > config.AUDIO_UPLOAD_LENGTH_LIMIT ||
    length < config.AUDIO_UPLOAD_LENGTH_MINIMUM
  ) {
    res.success = false
    res.limit = {
      minLength: config.AUDIO_UPLOAD_LENGTH_MINIMUM,
      maxLength: config.AUDIO_UPLOAD_LENGTH_LIMIT
    }
  }

  return res
}

/**
 * File Reader
 */

export const audioFileReader = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = (e) => {
      // https://ourcodeworld.com/articles/read/1036/how-to-retrieve-the-duration-of-a-mp3-wav-audio-file-in-the-browser-with-javascript
      const audio = document.createElement('audio')
      // audio.src = e.target.result
      audio.src = URL.createObjectURL(file) // https://medium.com/@dineshvasudevan/duration-of-an-audio-file-via-javascript-e8d78f26b15f
      audio.addEventListener(
        'loadedmetadata',
        () => {
          // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
          const duration = audio.duration

          // example 12.3234 seconds
          // console.log('The duration of the song is of: ' + duration + ' seconds')
          // Alternatively, just display the integer value with
          // parseInt(duration)
          // 12 seconds
          resolve(parseInt(duration))
        },
        false
      )
    }
    reader.onerror = (error) => reject(error)
  })
}

export const audioURLReader = (url) => {
  return new Promise((resolve, reject) => {
    // https://ourcodeworld.com/articles/read/1036/how-to-retrieve-the-duration-of-a-mp3-wav-audio-file-in-the-browser-with-javascript

    // Create a non-dom allocated Audio element
    const audio = document.createElement('audio')

    // Define the URL of the MP3 audio file
    audio.src = url

    // Once the metadata has been loaded, display the duration in the console
    audio.addEventListener(
      'loadedmetadata',
      function () {
        // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
        var duration = audio.duration

        // example 12.3234 seconds
        // console.log('The duration of the song is of: ' + duration + ' seconds')
        // Alternatively, just display the integer value with
        // parseInt(duration)
        // 12 seconds
        resolve(parseInt(duration))
      },
      false
    )
  })
}

/**
 * Get query for the datatable
 * page
 * perpage
 */
export const getPageSizeFromURL = (query, location) => {
  if (query.charAt(0) === '?') {
    // eslint-disable-line
    query = query.substring(1) // eslint-disable-line
  }
  query = querystring.parse(query)

  if (
    Object.keys(query).length > 0 &&
    ((Object.keys(query).includes('page') &&
      Object.keys(query).includes('perpage')) ||
      Object.keys(query).includes('tab'))
  ) {
    const page = location.search ? Number(query.page || 1) : 1 // eslint-disable-line
    const sizePerPage = Number(query.perpage || 10) // eslint-disable-line
    const queryParams = {
      offset: Number((page - 1) * sizePerPage), // eslint-disable-line
      limit: Number(sizePerPage) // eslint-disable-line
    }

    return { queryParams, data: { ...query, page, sizePerPage } }
  }
  return false
}

/**
 * Get query for the datatable
 * page
 * perpage
 */
export const addPageSizeInURL = (
  page,
  sizePerPage,
  history,
  addQueryPayload = {}
) => {
  if (page > 0) {
    history.push({
      search: querystring.stringify({
        page: page,
        perpage: sizePerPage,
        ...addQueryPayload
      })
    })
  }
}

export const updateQueryInURL = (history, query = {}) => {
  history.push({
    search: querystring.stringify(query)
  })
}

/**
 * Title Case
 */
export const titleCase = (str) => {
  if (str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => {
        if (word) {
          if (word.includes('_')) {
            return word
              .replace(
                word.charAt(word.search('_') + 1),
                word.charAt(word.search('_') + 1).toUpperCase()
              )
              .replace('_', ' ')
              .replace(word[0], word[0].toUpperCase())

            // return word
            //   .replace('_', ' ')
            //   .replace(word[0], word[0].toUpperCase())
          }
          return word.replace(word[0], word[0].toUpperCase())
        }
        return word
      })
      .join(' ')
  }
  return str
}

/**
 * Filter data
 */
export const filterDataObj = (values) => {
  logger({ values })
  const filterData = {}
  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key)) {
      if (values[key] && key === 'createdAt') {
        for (let index = 0; index < values[key].length; index++) {
          const element = values[key][index]
          if (index === 0) {
            filterData.fromDate = toSendDateInApi(element)
          }
          if (index === 1) {
            filterData.toDate = toSendDateInApi(element)
          }
        }
      } else {
        if (values[key]) {
          if (values[key]) {
            filterData[key] = values[key]
          }
        }
      }
    }
  }
  const filterCount = filterDataCount(filterData)
  return {
    filterData,
    filterCount
  }
}

export const filterDataObjWithDob = (values) => {
  logger({ values })
  const filterData = {}
  for (const key in values) {
    if (Object.hasOwnProperty.call(values, key)) {
      if (values[key] && key === 'dob') {

        filterData.dob = toSendDateInApi(values[key])

        
      } else {
        if (values[key]) {
          if (values[key]) {
            filterData[key] = values[key]
          }
        }
      }
    }
  }
  const filterCount = filterDataCount(filterData)
  return {
    filterData,
    filterCount
  }
}

/**
 * Filter Count Management
 */
export const filterDataCount = (filterObj) => {
  let count = Object.keys(filterObj).length || 0
  if (
    count > 0 &&
    Object.keys(filterObj).includes('fromDate') &&
    Object.keys(filterObj).includes('toDate')
  ) {
    count -= 1
  }

  return <span className="nowrap-space">{count}</span>
}

/**
 * Common
 */
export const infiniteLimitForListing = 4294967295

export const acceptImageFiles = '.png, .jpg, .jpeg'

export const acceptAudioFiles = '.mp3'

export const acceptVideoFiles = '.mp4'

export const acceptDocFiles = '.pdf, .docx'

export const acceptMediaFiles = '.png,.pdf, .docx, .jpg, .jpeg,.mp4,.mp3'

// CheckFileType

export const iconAccordingToFileType = (mediaType) =>{
  let data
  if(["pdf"].includes(mediaType)) {
    data = <img src="/assets/images/pdf.svg" alt={mediaType.toUpperCase()} className="img-fluid" />
  }else if(["doc", "docx"].includes(mediaType)){
    data = <img src="/assets/images/doc.svg" alt={mediaType.toUpperCase()} className="img-fluid" />
  }else if(["mp3"].includes(mediaType)){
    data = <img src="/assets/images/mp3.svg" alt={mediaType.toUpperCase()} className="img-fluid" />
  }else if(["mp4", "youtube"].includes(mediaType)) {
    data = <img src="/assets/images/mp4.svg" alt={mediaType.toUpperCase()} className="img-fluid" />
  }else if(["png", "jpg", "jpeg"].includes(mediaType)){
    data = <img src="/assets/images/png.svg" alt={mediaType.toUpperCase()} className="img-fluid" />
  }
  return data
}

export const countryCodeList = () => {
return(
  <>
  {countryDialCode.map((data, index) => (
        <option key={index} value={data.value}>
            {data.label.toUpperCase()} (+{data.value})
        </option>
    ))}
  </>
)
}
