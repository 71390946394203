import React, { useState,useEffect } from "react";
import NotificationItem from "./notificationItem";
import CustomScrollbars from "../common/customScrollbars";
import Auxiliary from "../common/auxiliary";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/auth/authSlice";
import { getNotificationList } from "../../services/notification";
import logger from "../../utilities/logger";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getNotificationCount } from "../../redux/common/commonSlice";

const AppNotification = () => {
  const { t } = useTranslation() 
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData)
  const {userType} = userData
  const [notificationList,setNotificationList] = useState([])
  const [loader,setLoader] = useState(false)

  useEffect(()=>{
    fetchNotificationList()
  },[])

  const fetchNotificationList = async () =>{
    try{
      let queryParams = {limit: 4,offset:0}
      const res = await getNotificationList({queryParams})
      if(res && res.success){
        let {data} = res
        dispatch(getNotificationCount())
        setNotificationList(data.rows)
        setLoader(true)
      }
    }catch(error){
      logger(error)
      setLoader(true)
    }
  }

  return (
    <Auxiliary>
      <div className="popover-header">
        <h3 className="mb-0">{t('notification.title')}</h3>
        <i className="icon-btn icon icon-charvlet-down"/>
      </div>
      <CustomScrollbars className="popover-scroll">
        <ul className="sub-popover">
        { loader === false ? <div className="listLoader">
                        <LoadingOutlined /> 
                    </div> : notificationList.length === 0 ? <Empty description={<span>{t('notification.noNotification')}</span>}/>:
          notificationList.map((data,index)=>{
          return(
            <NotificationItem key={index} notification={data}/>
          )
        })}
        </ul>
      </CustomScrollbars>
      {notificationList.length > 0 &&
      <div className="popover-bottom text-center">
        <Link to={`/${userType}/notification`} className="ant-btn ant-btn-info">{t('notification.viewBtn')}</Link>
      </div>}
    </Auxiliary>
  )
};

export default AppNotification;

