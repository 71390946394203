import APIrequest from '../apiRequest'
import ApiEndPoints from '../../utilities/apiEndPoints'
import logger from '../../utilities/logger'

export const getCountryList = async () => {
    const payload = {
        ...ApiEndPoints.countryList,
    }
    try {
        return await APIrequest(payload)
    } catch (error) {
        logger(error)
        throw error
    }
}


export const getStateList = async () => {
    const payload = {
        ...ApiEndPoints.stateList,
    }
    try {
        return await APIrequest(payload)
    } catch (error) {
        logger(error)
        throw error
    }
}

export const getDegreeList = async () => {
    try {
        const payload = {
            ...ApiEndPoints.degreeList
        }
        return await APIrequest(payload)
    } catch (error) {
        logger(error)
        throw error
    }
}
