import APIrequest from '../apiRequest'
import ApiEndPoints from '../../utilities/apiEndPoints'
import logger from '../../utilities/logger'

export const getNotificationList = async ({queryParams}) => {
    const payload = {
        ...ApiEndPoints.getNotificationList,
        queryParams
    }
    try {
        return await APIrequest(payload)
    } catch (error) {
        logger(error)
        throw error
    }
}

export const getNotificationStatus = async () => {
    const payload = {
        ...ApiEndPoints.getNotificationStatus
    }
    try {
        return await APIrequest(payload)
    } catch (error) {
        logger(error)
        throw error
    }
}

export const getNotificationUnreadCount = async () => {
    const payload = {
        ...ApiEndPoints.notificationUnreadCount
    }
    try {
        return await APIrequest(payload)
    } catch (error) {
        logger(error)
        throw error
    }
}

export const deleteNotification = async (id) => {
    const payload = {
        ...ApiEndPoints.deleteNotification(id)
    }
    try {
        return await APIrequest(payload)
    } catch (error) {
        logger(error)
        throw error
    }
}
