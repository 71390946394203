import React, { lazy } from 'react';
import RouteWithLayout from './routeWithLayout'
import { WithAuth } from '../utilities/withAuth'
import MainLayout from '../layouts/main'
import LoginLayout from '../layouts/login'

const Dashboard = lazy(() => import('../container/dashboard/admin'))
const ClinicList = lazy(() => import('../container/clinicList'))
const AddEditClinic = lazy(() => import('../container/clinicList/addEditClinic'))
const ViewClinic = lazy(() => import('../container/clinicList/viewClinic'))
const DoctorList = lazy(() => import('../container/users/doctorList'))
const DoctorView = lazy(()=> import('../container/users/doctorList/viewDoctor'))
const PatientList = lazy(() => import('../container/users/patientList'))
const NurseList = lazy(() => import('../container/users/nurseList'))
const AdminLogin = lazy(() => import('../container/login/adminLogin'))
const forgetPassword = lazy(() => import('../container/forgetPassword'))
const PatientDetail = lazy(() => import('../container/users/patientList/view/patientView'))
const AddEditPatient = lazy(() => import('../container/users/patientList/addEditPatient'))
const AddEditDoctor = lazy(() => import('../container/users/doctorList/addEditDoctor'));
const AdminProfile = lazy(() => import('../container/userProfile/adminProfile'));

const DischargeSummary = lazy(() => import('../container/dischargeSummary/dischargeList'))
const Report = lazy(() => import('../container/report'))
const AuditReport = lazy(() => import('../container/report/auditReport'))
const NotificationList = lazy(()=> import('../container/notification'))
const Speciality = lazy(()=> import('../container/speciality'))

function AdminRoutes(props) {
    return (
        <>
        <RouteWithLayout
              component={AdminLogin}
              exact
              layout={WithAuth(LoginLayout)}
              path='/admin'
            />
            <RouteWithLayout
              component={forgetPassword}
              layout={LoginLayout}
              path='/admin/forgot-password'
              exact
            />
            <RouteWithLayout
              component={Dashboard}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/dashboard'
            />
            <RouteWithLayout
              component={ClinicList}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/clinic-list'
            />
            <RouteWithLayout
              component={DoctorList}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/doctor-list'
            />
            <RouteWithLayout
              component={PatientList}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/patient-list'
            />
            <RouteWithLayout
              component={NurseList}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/nurse-list'
            />
            <RouteWithLayout
              component={ClinicList}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/clinic-admin'
            />
            <RouteWithLayout
              component={ClinicList}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/urgent-care'
            />
            <RouteWithLayout
              component={AddEditClinic}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/add-clinic'
            />
            <RouteWithLayout
              component={ViewClinic}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/view-clinic/:id'
            />
            <RouteWithLayout
              component={AddEditClinic}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/edit-clinic/:id'
            />
            <RouteWithLayout
              component={PatientDetail}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/patient-detail/:id'
            />
            <RouteWithLayout
              component={AddEditPatient}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/add-patient'
            />
            <RouteWithLayout
              component={AddEditPatient}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/patient-edit/:id'
            />
            <RouteWithLayout
              component={AddEditDoctor}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/doctor-add' 
            />
            <RouteWithLayout
              component={AddEditDoctor}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/doctor-edit/:id' />
            <RouteWithLayout
              component={DischargeSummary}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/discharge-summary'
            />
            <RouteWithLayout
              component={DoctorView}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/view-doctor/:id' 
            />
            <RouteWithLayout
              component={AdminProfile}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/profile' 
            />  
            <RouteWithLayout
              component={Report}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/report' 
            />  
            <RouteWithLayout
              component={AuditReport}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/audit-report' 
            />  
            <RouteWithLayout
              component={NotificationList}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/notification' 
            />  
            <RouteWithLayout
              component={Speciality}
              exact
              layout={WithAuth(MainLayout)}
              path='/admin/speciality' 
            />    
        </>
    );
}

export default AdminRoutes;