import React, { lazy } from 'react';
import RouteWithLayout from './routeWithLayout'
import { WithAuth } from '../utilities/withAuth'
import MainLayout from '../layouts/main'

const PatientDashboard = lazy(() => import('../container/dashboard/patient'))
const PatientProfile = lazy(() => import('../container/userProfile/patientProfile'));
const AddEditPatient = lazy(() => import('../container/users/patientList/addEditPatient'))
const PatientSetting = lazy(()=> import('../container/setting'))
const DoctorList = lazy(()=> import('../container/patient/doctorList'))
const BookAppointment = lazy(()=> import('../container/patient/bookAppointment'))
const WaitingRoom = lazy(()=> import('../container/patient/waitingRoom'))
const AppointmentList = lazy(()=> import('../container/patient/appointment'))
const PatientVideoCall = lazy(()=> import('../container/patient/patientVideoCall'))
const Messgae = lazy(()=> import('../container/message'))
const NotificationList = lazy(()=> import('../container/notification'))
const PatientTools = lazy(() => import('../container/clinic/patientTools'))
const Survey = lazy(()=>import('../container/patient/survey'))
const DoctorView = lazy(()=> import('../container/users/doctorList/viewDoctor'))





function PatientRoutes(props) {
    return (
        <>
            <RouteWithLayout
              component={PatientDashboard}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/dashboard'
            />
            <RouteWithLayout
              component={PatientProfile}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/profile' 
            /> 
             <RouteWithLayout
              component={AddEditPatient}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/edit-profile' 
            />
            <RouteWithLayout
              component={PatientSetting}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/setting' 
            /> 
            <RouteWithLayout
              component={DoctorList}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/doctor-list' 
            />  
            <RouteWithLayout
              component={BookAppointment}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/book-appointment/:id' 
            />
            <RouteWithLayout
              component={WaitingRoom}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/waiting-room/:code' 
            /> 
            <RouteWithLayout
              component={AppointmentList}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/appointment' 
            />   
            <RouteWithLayout
              component={PatientVideoCall}
              exact
              layout={WithAuth(MainLayout)}
              path='/video-call/:code'
            />
            <RouteWithLayout
              component={Messgae}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/message'
            /> 
            <RouteWithLayout
              component={Messgae}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/message/:id'
            /> 
            <RouteWithLayout
              component={NotificationList}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/notification' 
            /> 
            <RouteWithLayout
              component={PatientTools}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/tools'
            />
            <RouteWithLayout
              component={Survey}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/survey'
            />
            <RouteWithLayout
              component={DoctorView}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/doctor-profile/:id' 
            />
            <RouteWithLayout
              component={BookAppointment}
              exact
              layout={WithAuth(MainLayout)}
              path='/patient/reschedule-appointment/:id/:appId' 
            />
        </>
    );
}

export default PatientRoutes;