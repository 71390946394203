import React,{useState} from "react";
import {useDispatch,useSelector} from "react-redux";
import {Avatar, Popover} from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import { logout, selectUserData } from '../../redux/auth/authSlice';

const UserInfo = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const data = useSelector(selectUserData);
  const userType = data.userType
  const [isDisabled,setIsDisabled] = useState(false)
  

  const userMenuOptions = (
    <ul className="user-popover">
      <li><Link to={`/${userType}/profile`}>{t('common.myProfile')}</Link></li>     
      {(userType !== 'admin') &&
      <li><Link to={`/${userType}/setting`}>{t('common.settings')}</Link></li>
      } 
      <li>
          <Link to='#' disabled={isDisabled}
           onClick={(e) => {e.preventDefault();
           dispatch(logout(userType,history,setIsDisabled,t('login.logoutMessage')))
           }}
           >
           {t('login.logout')}
           </Link>
      </li>
    </ul>
  );

  return (
    <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={`${data.photo}`} className="size-40 pointer mr-1 mr-sm-3" alt=""/>
        <span className="avatar-name">{data.userName}<i className="icon icon-chevron-down fs-xxs ml-2"/></span>
    </Popover>
  );
};

export default UserInfo;
