import APIrequest from '../apiRequest'
import ApiEndPoints from '../../utilities/apiEndPoints'
import logger from '../../utilities/logger'

export const authListService = async (bodyData, userType) => {
  let payload;
  if (userType === 'admin') {
    payload = {
      ...ApiEndPoints.adminLogin,
      bodyData
    }
  } else {
    payload = {
      ...ApiEndPoints.userLogin,
      bodyData
    }
  }

  try {
    return await APIrequest(payload)
  } catch (error) {
    logger(error)
    throw error
  }
}
export const changePassService = async (bodyData) => {
  const payload = {
    ...ApiEndPoints.changePassword,
    bodyData
  }
  try {
    return await APIrequest(payload)
  } catch (error) {
    logger(error)
    throw error
  }
}
export const logoutAuth = async () => {
  const payload = {
    ...ApiEndPoints.logout
  }
  try {
    return await APIrequest(payload)
  } catch (error) {
    logger(error)
    throw error
  }
}

export const forgetPassService = async (bodyData) => {
  const payload = {
    ...ApiEndPoints.forgetPassword,
    bodyData
  }
  try {
    return await APIrequest(payload)
  } catch (error) {
    logger(error)
    throw error
  }
}

export const checkEmailAndUserNameExistsService = async (bodyData) => {

  try {
    const payload = {
      ...ApiEndPoints.checkEmailAndUserName,
      bodyData
    }
    return await APIrequest(payload);
  } catch (error) {
    throw Error(error.message)
  }
}

export const checkClinicIdExistsService = async (bodyData) => {

  try {
    const payload = {
      ...ApiEndPoints.checkClinicID,
      bodyData
    }
    return await APIrequest(payload);
  } catch (error) {
    throw Error(error.message)
  }
}

export const checkNpiAndResourceExistsService = async (bodyData) => {

  try {
    const payload = {
      ...ApiEndPoints.checkNpiAndResource,
      bodyData
    }
    return await APIrequest(payload);
  } catch (error) {
    throw Error(error.message)
  }
}

export const userForgetPassService = async (bodyData) => {
  const payload = {
    ...ApiEndPoints.userForgetPassword,
    bodyData
  }
  try {
    return await APIrequest(payload)
  } catch (error) {
    logger(error)
    throw error
  }
}

export const userResetPassService = async (bodyData) => {
  const payload = {
    ...ApiEndPoints.userResetPassword,
    bodyData
  }
  try {
    return await APIrequest(payload)
  } catch (error) {
    logger(error)
    throw (error)
  }
}
