import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "../common/customScrollbars";
import SidebarLogo from "./sidebarLogo";
import { useDispatch, useSelector } from "react-redux";
import { routesJSON } from "../../utilities/sidebarRoutes/index"
import { useTranslation } from 'react-i18next'
import { useLocation } from "react-router";
const { SubMenu } = Menu;

const SidebarContent = ({ sidebarCollapsed, clickSidebarCollapsed }) => {
  const { t } = useTranslation()
  const location = useLocation();
  const userType = useSelector((state) => state.auth.userData.userType)
  
  let user = userType?.charAt(0)?.toUpperCase() + userType?.slice(1)  
  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} clickSidebarCollapsed={clickSidebarCollapsed} />
      <div className="sidebar-content">
        <CustomScrollbars className="layout-sider-scrollbar">
          <Menu
            defaultSelectedKeys={['1']}
            defaultOpenKeys={[""]}
            theme={'dark'}
            mode="inline">
              {  
                routesJSON(t).filter(item =>item[`addIn${user}SideBar`] === true).map(menu => {
                
                let activeClass = '';
                if (location.pathname === menu.active[0]){
                  activeClass = "ant-menu-item-selected";
                }
                if (menu.child){
                  return (
                    <SubMenu key={menu.key} className="ant-menu-title-content"
                      title={<span> <i className={menu.icon} />
                        <span>{menu.title}</span> 
                        </span>}>
                          {menu.child.map(subMenu => {
                            
                            return (
                              <Menu.Item key={subMenu.key} className={`ant-menu-title-content ${location.pathname === subMenu.active[0] ? 'ant-menu-item-selected' : ''}`}>
                                <Link to={subMenu.endPoint} >                 
                                  <span>{subMenu.title}</span> 
                                </Link>
                              </Menu.Item>
                            )
                          })}    
                    </SubMenu>
                  )
                } else {
                   return (
                    <Menu.Item key={menu.key} className={`ant-menu-title-content ${activeClass}`} >
                      <Link to={menu.endPoint}><i className={menu.icon} />
                      <span>{menu.title}</span> 
                      </Link>
                    </Menu.Item>
                   ) 
                }
              })}
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;






