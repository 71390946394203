export const clinicRoutes = (t) => [
    {
        endPoint: '/clinic/dashboard',
        authRequire: true,
        addInClinicSideBar: true,
        active: ['/clinic/dashboard'],
        title: t('dashboard.title'),
        icon: 'icon icon-widgets',
        userTypes: ['clinic'],
        key: 'clinicDashboard'
      },
      {
        endPoint: '/clinic/profile',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/profile'],
        userTypes: ['clinic']
      },
      {
        endPoint: '/clinic/edit-profile',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/edit-profile'],
        userTypes: ['clinic']
      },
      {
        endPoint: '/clinic/setting',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/setting'],
        title: t('dashboard.title'),
        icon: 'icon icon-widgets',
        userTypes: ['clinic'],
        key: 'setting'
      },
      {
        endPoint: 'users',
        authRequire: true,
        addInClinicSideBar: true,
        active: [
          '/users',
          '/clinic/doctor-list',
          '/clinic/patient-list',
        ],
        title: t('users.title'),
        icon: 'icon icon-user',
        userTypes: ['clinic'],
        key: ['users'],
        child: [
          {
            endPoint: '/clinic/doctor-list',
            authRequire: true,
            addInClinicSideBar: true,
            active: ['/clinic/doctor-list'],
            title: t('users.doctors'),
            icon: '',
            userTypes: ['clinic'],
            key: 'doctorlist'
          },
          {
            endPoint: '/clinic/patient-list',
            authRequire: true,
            addInClinicSideBar: true,
            active: ['/clinic/patient-list', '/clinic/add-patient', '/patient-detail/:id', '/clinic/patient-edit/:id'],
            title: t('users.patients'),
            icon: '',
            userTypes: ['clinic'],
            key: 'patientlist'
          },
        ]
      },
      {
        endPoint: '/clinic/message',
        authRequire: true,
        addInClinicSideBar: true,
        active: ['/clinic/message'],
        title: t('common.message'),
        icon: 'icon ico-comment',
        userTypes: ['clinic'],
        key:'message'
      },
      {
        endPoint: '/clinic/daily-appointment',
        authRequire: true,
        addInClinicSideBar: true,
        active: ['/clinic/daily-appointment'],
        title: t('clinics.dailyAppointment'),
        icon: 'icon ico-appointments-fill',
        userTypes: ['clinic'],
        key: 'dailyAppointment'
      },
      {
        endPoint: '/clinic/dischargesummary',
        authRequire: true,
        addInClinicSideBar: true,
        active: ['/clinic/dischargesummary'],
        title: t('dischargeSummary.title'),
        icon: 'icon ico-notepad',
        userTypes: ['clinic'],
        key: 'dischargesummarylist'
      },
      {
        endPoint: '/clinic/report',
        authRequire: true,
        addInClinicSideBar: true,
        active: ['/clinic/report'],
        title: t('common.report'),
        icon: 'icon ico-file',
        userTypes: ['clinic'],
        key: 'report',
      },
      {
        endPoint: '/clinic/patient-portal',
        authRequire: true,
        addInClinicSideBar: true,
        active: ['/clinic/patient-portal'],
        title: t('patientPortal.title'),
        icon: 'icon ico-pharmacy',
        userTypes: ['clinic'],
        key: 'patientPortallist'
      },
      {
        endPoint: '/clinic/update-survey',
        authRequire: true,
        addInClinicSideBar: true,
        active: ['/clinic/update-survey'],
        title: t('clinics.survey'),
        icon: 'icon ico-upload',
        userTypes: ['clinic'],
        key: 'setting'
      },
      {
        endPoint: 'tools',
        authRequire: true,
        addInClinicSideBar: true,
        active: [
          '/tools',
          '/clinic/patient-tools',
          '/clinic/doctor-tools',
        ],
        title: t('clinics.tools'),
        icon: 'icon ico-cog',
        userTypes: ['clinic'],
        key: ['tools'],
        child: [
          {
            endPoint: '/clinic/patient-tools',
            authRequire: true,
            addInClinicSideBar: true,
            active: ['/clinic/patient-tools'],
            title: t('clinics.patientTool'),
            icon: '',
            userTypes: ['clinic'],
            key: 'doctorlist'
          },
          {
            endPoint: '/clinic/doctor-tools',
            authRequire: true,
            addInClinicSideBar: true,
            active: ['/clinic/doctor-tools'],
            title: t('clinics.doctorTool'),
            icon: '',
            userTypes: ['clinic'],
            key: 'patientlist'
          },
        ]
      },
      {
        endPoint: '/clinic/doctor-add',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/doctor-add'],
        userTypes: ['clinic']
      },
      {
        endPoint: '/clinic/doctor-edit/:id',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/doctor-edit/:id'],
        userTypes: ['clinic'],
      },
      {
        endPoint: '/clinic/view-doctor/:id',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/view-doctor/:id'],
        userTypes: ['clinic'],
      },
      {
        endPoint: '/clinic/add-patient',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/add-patient'],
        userTypes: ['clinic'],
        moduleKey: 'patients',
      },
      {
        endPoint: '/clinic/patient-detail/:id',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/patient-detail/:id'],
        userTypes: ['clinic'],
        moduleKey: 'patients'
      },
      {
        endPoint: '/clinic/patient-edit/:id',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/patient-edit/:id'],
        userTypes: ['clinic'],
        moduleKey: 'patients'
      },
      {
        endPoint: '/clinic/notification',
        authRequire: true,
        addInClinicSideBar: false,
        active: ['/clinic/notification'],
        title: t('notification.title'),
        icon: 'icon ico-pharmacy',
        userTypes: ['clinic']
      }
]