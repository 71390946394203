export const countryDialCode = [
    {
      "name": "United States",
      "label": "us",
      "value": "1",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "United Kingdom",
      "label": "gb",
      "value": "44",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Afghanistan (‫افغانستان‬‎)",
      "label": "af",
      "value": "93",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Albania (Shqipëri)",
      "label": "al",
      "value": "355",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Algeria (‫الجزائر‬‎)",
      "label": "dz",
      "value": "213",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "American Samoa",
      "label": "as",
      "value": "1684",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Andorra",
      "label": "ad",
      "value": "376",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Angola",
      "label": "ao",
      "value": "244",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Anguilla",
      "label": "ai",
      "value": "1264",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Antigua and Barbuda",
      "label": "ag",
      "value": "1268",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Argentina",
      "label": "ar",
      "value": "54",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Armenia (Հայաստան)",
      "label": "am",
      "value": "374",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Aruba",
      "label": "aw",
      "value": "297",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Australia",
      "label": "au",
      "value": "61",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Austria (Österreich)",
      "label": "at",
      "value": "43",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Azerbaijan (Azərbaycan)",
      "label": "az",
      "value": "994",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Bahamas",
      "label": "bs",
      "value": "1242",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Bahrain (‫البحرين‬‎)",
      "label": "bh",
      "value": "973",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Bangladesh (বাংলাদেশ)",
      "label": "bd",
      "value": "880",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Barbados",
      "label": "bb",
      "value": "1246",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Belarus (Беларусь)",
      "label": "by",
      "value": "375",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Belgium (België)",
      "label": "be",
      "value": "32",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Belize",
      "label": "bz",
      "value": "501",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Benin (Bénin)",
      "label": "bj",
      "value": "229",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Bermuda",
      "label": "bm",
      "value": "1441",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Bhutan (འབྲུག)",
      "label": "bt",
      "value": "975",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Bolivia",
      "label": "bo",
      "value": "591",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Bosnia and Herzegovina (Босна и Херцеговина)",
      "label": "ba",
      "value": "387",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Botswana",
      "label": "bw",
      "value": "267",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Brazil (Brasil)",
      "label": "br",
      "value": "55",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "British Indian Ocean Territory",
      "label": "io",
      "value": "246",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "British Virgin Islands",
      "label": "vg",
      "value": "1284",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Brunei",
      "label": "bn",
      "value": "673",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Bulgaria (България)",
      "label": "bg",
      "value": "359",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Burkina Faso",
      "label": "bf",
      "value": "226",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Burundi (Uburundi)",
      "label": "bi",
      "value": "257",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Cambodia (កម្ពុជា)",
      "label": "kh",
      "value": "855",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Cameroon (Cameroun)",
      "label": "cm",
      "value": "237",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Canada",
      "label": "ca",
      "value": "1",
      "priority": 1,
      "areaCodes": [
      ]
    },
    {
      "name": "Cape Verde (Kabu Verdi)",
      "label": "cv",
      "value": "238",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Caribbean Netherlands",
      "label": "bq",
      "value": "599",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Cayman Islands",
      "label": "ky",
      "value": "1345",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Central African Republic (République centrafricaine)",
      "label": "cf",
      "value": "236",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Chad (Tchad)",
      "label": "td",
      "value": "235",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Chile",
      "label": "cl",
      "value": "56",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "China (中国)",
      "label": "cn",
      "value": "86",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Christmas Island",
      "label": "cx",
      "value": "61",
      "priority": 2,
      "areaCodes": null
    },
    {
      "name": "Cocos (Keeling) Islands",
      "label": "cc",
      "value": "61",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Colombia",
      "label": "co",
      "value": "57",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Comoros (‫جزر القمر‬‎)",
      "label": "km",
      "value": "269",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
      "label": "cd",
      "value": "243",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Congo (Republic) (Congo-Brazzaville)",
      "label": "cg",
      "value": "242",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Cook Islands",
      "label": "ck",
      "value": "682",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Costa Rica",
      "label": "cr",
      "value": "506",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Côte d’Ivoire",
      "label": "ci",
      "value": "225",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Croatia (Hrvatska)",
      "label": "hr",
      "value": "385",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Cuba",
      "label": "cu",
      "value": "53",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Curaçao",
      "label": "cw",
      "value": "599",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Cyprus (Κύπρος)",
      "label": "cy",
      "value": "357",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Czech Republic (Česká republika)",
      "label": "cz",
      "value": "420",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Denmark (Danmark)",
      "label": "dk",
      "value": "45",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Djibouti",
      "label": "dj",
      "value": "253",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Dominica",
      "label": "dm",
      "value": "1767",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Dominican Republic (República Dominicana)",
      "label": "do",
      "value": "1",
      "priority": 2,
      "areaCodes": [
        "809",
        "829",
        "849"
      ]
    },
    {
      "name": "Ecuador",
      "label": "ec",
      "value": "593",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Egypt (‫مصر‬‎)",
      "label": "eg",
      "value": "20",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "El Salvador",
      "label": "sv",
      "value": "503",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Equatorial Guinea (Guinea Ecuatorial)",
      "label": "gq",
      "value": "240",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Eritrea",
      "label": "er",
      "value": "291",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Estonia (Eesti)",
      "label": "ee",
      "value": "372",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Ethiopia",
      "label": "et",
      "value": "251",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Falkland Islands (Islas Malvinas)",
      "label": "fk",
      "value": "500",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Faroe Islands (Føroyar)",
      "label": "fo",
      "value": "298",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Fiji",
      "label": "fj",
      "value": "679",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Finland (Suomi)",
      "label": "fi",
      "value": "358",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "France",
      "label": "fr",
      "value": "33",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "French Guiana (Guyane française)",
      "label": "gf",
      "value": "594",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "French Polynesia (Polynésie française)",
      "label": "pf",
      "value": "689",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Gabon",
      "label": "ga",
      "value": "241",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Gambia",
      "label": "gm",
      "value": "220",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Georgia (საქართველო)",
      "label": "ge",
      "value": "995",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Germany (Deutschland)",
      "label": "de",
      "value": "49",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Ghana (Gaana)",
      "label": "gh",
      "value": "233",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Gibraltar",
      "label": "gi",
      "value": "350",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Greece (Ελλάδα)",
      "label": "gr",
      "value": "30",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Greenland (Kalaallit Nunaat)",
      "label": "gl",
      "value": "299",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Grenada",
      "label": "gd",
      "value": "1473",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Guadeloupe",
      "label": "gp",
      "value": "590",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Guam",
      "label": "gu",
      "value": "1671",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Guatemala",
      "label": "gt",
      "value": "502",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Guernsey",
      "label": "gg",
      "value": "44",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Guinea (Guinée)",
      "label": "gn",
      "value": "224",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Guinea-Bissau (Guiné Bissau)",
      "label": "gw",
      "value": "245",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Guyana",
      "label": "gy",
      "value": "592",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Haiti",
      "label": "ht",
      "value": "509",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Honduras",
      "label": "hn",
      "value": "504",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Hong Kong (香港)",
      "label": "hk",
      "value": "852",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Hungary (Magyarország)",
      "label": "hu",
      "value": "36",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Iceland (Ísland)",
      "label": "is",
      "value": "354",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "India (भारत)",
      "label": "in",
      "value": "91",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Indonesia",
      "label": "id",
      "value": "62",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Iran (‫ایران‬‎)",
      "label": "ir",
      "value": "98",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Iraq (‫العراق‬‎)",
      "label": "iq",
      "value": "964",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Ireland",
      "label": "ie",
      "value": "353",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Isle of Man",
      "label": "im",
      "value": "44",
      "priority": 2,
      "areaCodes": null
    },
    {
      "name": "Israel (‫ישראל‬‎)",
      "label": "il",
      "value": "972",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Italy (Italia)",
      "label": "it",
      "value": "39",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Jamaica",
      "label": "jm",
      "value": "1876",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Japan (日本)",
      "label": "jp",
      "value": "81",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Jersey",
      "label": "je",
      "value": "44",
      "priority": 3,
      "areaCodes": null
    },
    {
      "name": "Jordan (‫الأردن‬‎)",
      "label": "jo",
      "value": "962",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Kazakhstan (Казахстан)",
      "label": "kz",
      "value": "7",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Kenya",
      "label": "ke",
      "value": "254",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Kiribati",
      "label": "ki",
      "value": "686",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Kuwait (‫الكويت‬‎)",
      "label": "kw",
      "value": "965",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Kyrgyzstan (Кыргызстан)",
      "label": "kg",
      "value": "996",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Laos (ລາວ)",
      "label": "la",
      "value": "856",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Latvia (Latvija)",
      "label": "lv",
      "value": "371",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Lebanon (‫لبنان‬‎)",
      "label": "lb",
      "value": "961",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Lesotho",
      "label": "ls",
      "value": "266",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Liberia",
      "label": "lr",
      "value": "231",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Libya (‫ليبيا‬‎)",
      "label": "ly",
      "value": "218",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Liechtenstein",
      "label": "li",
      "value": "423",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Lithuania (Lietuva)",
      "label": "lt",
      "value": "370",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Luxembourg",
      "label": "lu",
      "value": "352",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Macau (澳門)",
      "label": "mo",
      "value": "853",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Macedonia (FYROM) (Македонија)",
      "label": "mk",
      "value": "389",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Madagascar (Madagasikara)",
      "label": "mg",
      "value": "261",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Malawi",
      "label": "mw",
      "value": "265",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Malaysia",
      "label": "my",
      "value": "60",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Maldives",
      "label": "mv",
      "value": "960",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Mali",
      "label": "ml",
      "value": "223",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Malta",
      "label": "mt",
      "value": "356",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Marshall Islands",
      "label": "mh",
      "value": "692",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Martinique",
      "label": "mq",
      "value": "596",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Mauritania (‫موريتانيا‬‎)",
      "label": "mr",
      "value": "222",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Mauritius (Moris)",
      "label": "mu",
      "value": "230",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Mayotte",
      "label": "yt",
      "value": "262",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Mexico (México)",
      "label": "mx",
      "value": "52",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Micronesia",
      "label": "fm",
      "value": "691",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Moldova (Republica Moldova)",
      "label": "md",
      "value": "373",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Monaco",
      "label": "mc",
      "value": "377",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Mongolia (Монгол)",
      "label": "mn",
      "value": "976",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Montenegro (Crna Gora)",
      "label": "me",
      "value": "382",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Montserrat",
      "label": "ms",
      "value": "1664",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Morocco (‫المغرب‬‎)",
      "label": "ma",
      "value": "212",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Mozambique (Moçambique)",
      "label": "mz",
      "value": "258",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Myanmar (Burma) (မြန်မာ)",
      "label": "mm",
      "value": "95",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Namibia (Namibië)",
      "label": "na",
      "value": "264",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Nauru",
      "label": "nr",
      "value": "674",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Nepal (नेपाल)",
      "label": "np",
      "value": "977",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Netherlands (Nederland)",
      "label": "nl",
      "value": "31",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "New Caledonia (Nouvelle-Calédonie)",
      "label": "nc",
      "value": "687",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "New Zealand",
      "label": "nz",
      "value": "64",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Nicaragua",
      "label": "ni",
      "value": "505",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Niger (Nijar)",
      "label": "ne",
      "value": "227",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Nigeria",
      "label": "ng",
      "value": "234",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Niue",
      "label": "nu",
      "value": "683",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Norfolk Island",
      "label": "nf",
      "value": "672",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "North Korea (조선 민주주의 인민 공화국)",
      "label": "kp",
      "value": "850",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Northern Mariana Islands",
      "label": "mp",
      "value": "1670",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Norway (Norge)",
      "label": "no",
      "value": "47",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Oman (‫عُمان‬‎)",
      "label": "om",
      "value": "968",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Pakistan (‫پاکستان‬‎)",
      "label": "pk",
      "value": "92",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Palau",
      "label": "pw",
      "value": "680",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Palestine (‫فلسطين‬‎)",
      "label": "ps",
      "value": "970",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Panama (Panamá)",
      "label": "pa",
      "value": "507",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Papua New Guinea",
      "label": "pg",
      "value": "675",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Paraguay",
      "label": "py",
      "value": "595",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Peru (Perú)",
      "label": "pe",
      "value": "51",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Philippines",
      "label": "ph",
      "value": "63",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Poland (Polska)",
      "label": "pl",
      "value": "48",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Portugal",
      "label": "pt",
      "value": "351",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Puerto Rico",
      "label": "pr",
      "value": "1",
      "priority": 3,
      "areaCodes": [
        "787",
        "939"
      ]
    },
    {
      "name": "Qatar (‫قطر‬‎)",
      "label": "qa",
      "value": "974",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Réunion (La Réunion)",
      "label": "re",
      "value": "262",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Romania (România)",
      "label": "ro",
      "value": "40",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Russia (Россия)",
      "label": "ru",
      "value": "7",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Rwanda",
      "label": "rw",
      "value": "250",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Saint Barthélemy (Saint-Barthélemy)",
      "label": "bl",
      "value": "590",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Saint Helena",
      "label": "sh",
      "value": "290",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Saint Kitts and Nevis",
      "label": "kn",
      "value": "1869",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Saint Lucia",
      "label": "lc",
      "value": "1758",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Saint Martin (Saint-Martin (partie française))",
      "label": "mf",
      "value": "590",
      "priority": 2,
      "areaCodes": null
    },
    {
      "name": "Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)",
      "label": "pm",
      "value": "508",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Saint Vincent and the Grenadines",
      "label": "vc",
      "value": "1784",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Samoa",
      "label": "ws",
      "value": "685",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "San Marino",
      "label": "sm",
      "value": "378",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "São Tomé and Príncipe (São Tomé e Príncipe)",
      "label": "st",
      "value": "239",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
      "label": "sa",
      "value": "966",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Senegal (Sénégal)",
      "label": "sn",
      "value": "221",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Serbia (Србија)",
      "label": "rs",
      "value": "381",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Seychelles",
      "label": "sc",
      "value": "248",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Sierra Leone",
      "label": "sl",
      "value": "232",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Singapore",
      "label": "sg",
      "value": "65",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Sint Maarten",
      "label": "sx",
      "value": "1721",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Slovakia (Slovensko)",
      "label": "sk",
      "value": "421",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Slovenia (Slovenija)",
      "label": "si",
      "value": "386",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Solomon Islands",
      "label": "sb",
      "value": "677",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Somalia (Soomaaliya)",
      "label": "so",
      "value": "252",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "South Africa",
      "label": "za",
      "value": "27",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "South Korea (대한민국)",
      "label": "kr",
      "value": "82",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "South Sudan (‫جنوب السودان‬‎)",
      "label": "ss",
      "value": "211",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Spain (España)",
      "label": "es",
      "value": "34",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Sri Lanka (ශ්‍රී ලංකාව)",
      "label": "lk",
      "value": "94",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Sudan (‫السودان‬‎)",
      "label": "sd",
      "value": "249",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Suriname",
      "label": "sr",
      "value": "597",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Svalbard and Jan Mayen",
      "label": "sj",
      "value": "47",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Swaziland",
      "label": "sz",
      "value": "268",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Sweden (Sverige)",
      "label": "se",
      "value": "46",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Switzerland (Schweiz)",
      "label": "ch",
      "value": "41",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Syria (‫سوريا‬‎)",
      "label": "sy",
      "value": "963",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Taiwan (台灣)",
      "label": "tw",
      "value": "886",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Tajikistan",
      "label": "tj",
      "value": "992",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Tanzania",
      "label": "tz",
      "value": "255",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Thailand (ไทย)",
      "label": "th",
      "value": "66",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Timor-Leste",
      "label": "tl",
      "value": "670",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Togo",
      "label": "tg",
      "value": "228",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Tokelau",
      "label": "tk",
      "value": "690",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Tonga",
      "label": "to",
      "value": "676",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Trinidad and Tobago",
      "label": "tt",
      "value": "1868",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Tunisia (‫تونس‬‎)",
      "label": "tn",
      "value": "216",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Turkey (Türkiye)",
      "label": "tr",
      "value": "90",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Turkmenistan",
      "label": "tm",
      "value": "993",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Turks and Caicos Islands",
      "label": "tc",
      "value": "1649",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Tuvalu",
      "label": "tv",
      "value": "688",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "U.S. Virgin Islands",
      "label": "vi",
      "value": "1340",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Uganda",
      "label": "ug",
      "value": "256",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Ukraine (Україна)",
      "label": "ua",
      "value": "380",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
      "label": "ae",
      "value": "971",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Uruguay",
      "label": "uy",
      "value": "598",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Uzbekistan (Oʻzbekiston)",
      "label": "uz",
      "value": "998",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Vanuatu",
      "label": "vu",
      "value": "678",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Vatican City (Città del Vaticano)",
      "label": "va",
      "value": "39",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Venezuela",
      "label": "ve",
      "value": "58",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Vietnam (Việt Nam)",
      "label": "vn",
      "value": "84",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Wallis and Futuna",
      "label": "wf",
      "value": "681",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Western Sahara (‫الصحراء الغربية‬‎)",
      "label": "eh",
      "value": "212",
      "priority": 1,
      "areaCodes": null
    },
    {
      "name": "Yemen (‫اليمن‬‎)",
      "label": "ye",
      "value": "967",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Zambia",
      "label": "zm",
      "value": "260",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Zimbabwe",
      "label": "zw",
      "value": "263",
      "priority": 0,
      "areaCodes": null
    },
    {
      "name": "Åland Islands",
      "label": "ax",
      "value": "358",
      "priority": 1,
      "areaCodes": null
    }
  ];

export const doctorTitle = [
    {
        label: "Dr",
        value: 'Dr'
    },
    {
        label: "Mr",
        value: 'Mr'
    },
    {
        label: "Ms",
        value: 'Ms'
    },
    {
        label: "Mrs",
        value: 'Mrs'
    }
]