
import { prortc } from '../utilities/prortc'

export let calling = {};

calling.roomData = {};
calling.mode = '';
calling.prortc = prortc;
calling.t = ''
calling.firstInviteeId = '';

calling.setLanguage = (data) => {
    calling.t = data;
}

calling.setMediaStram = () => {
    if (calling.roomData && calling.mode !== '') {
        let roomData = calling.roomData;
        let mode = calling.mode;
        let videoData;
        if (mode === 'video') {
            videoData = {
                video: true,
                audio: true
            }
        } else {
            videoData = {
                video: false,
                audio: true
            }
        }
        console.log("ROOM DATA", roomData, "MODE", mode)
        calling.prortc.startCall(videoData, function () {
            calling.prortc.joinRoom(roomData);
        });
    }
}

calling.updateRoomData = (roomData, mode) => {
    calling.roomData = roomData;
    calling.mode = mode;
}


prortc.on('device_error', function (error) {
    var message = "";
    if (error === "NotFoundError" || (error === "DevicesNotFoundError")) {
        message = calling.t('videoCall.devicesNotFoundError');
    }
    if (error === "NotReadableError" || (error === "TrackStartError")) {
        message = calling.t('videoCall.notReadableError');
    }
    if (error === "OverconstrainedError" || (error === "ConstraintNotSatisfiedError")) {
        message = calling.t('videoCall.overConstrainedError');
    }
    if (error === "NotAllowedError" || (error === "PermissionDeniedError")) {
        message = calling.t('videoCall.notAllowedError');
    }
    if (error === "TypeError") {
        message = calling.t('videoCall.typeError');
    }
    if (error === "stream_not_readable") {
        message = calling.t('videoCall.errorDefault');
    }
    if (document.querySelector("#errorMessage")) {
        document.querySelector("#errorMessage").innerHTML = message;
        document.querySelector("#errorMsg").className = 'callingMessage';
    }
});

prortc.on('close_Reason', function (error) {
    var message = "";
    if (error === "io server disconnect" || (error === "io client disconnect")) {
        message = calling.t('videoCall.ioServerDisconnect');
    }
    if (error === "ping timeout") {
        message = calling.t('videoCall.pingTimeout');
    }
    if (error === "transport close" || (error === "transport error")) {
        message = calling.t('videoCall.transportError');
    }
    if (document.querySelector("#socketMessage")) {
        document.querySelector("#socketMessage").innerHTML = message;
        document.querySelector("#socketMsg").className = 'callingMessage';
    }
});

prortc.on('remove_invitee_stream', function (data) {
    if (calling.roomData.role === 'invitee') {
        document.querySelector("#invitee_div").className = 'rightScreen rightScreen--small d-none'; 
    } else {
            console.log("invitee assign data",data,calling.firstInviteeId);
            if (calling.firstInviteeId == data.user.userId) {
                console.log("first video remove");
                document.querySelector("#first_invitee_div").className = 'rightScreen d-none';
            } else {
                console.log("second video remove");
                document.querySelector("#second_invitee_div").className = 'rightScreen d-none';
            } 
        }           
      
});

prortc.on('new_peer', function () {
    setTimeout(function () {
        document.querySelector("#socketMsg").className = 'callingMessage d-none';
    }, 1000);
});

prortc.on('reconnect_attempt', function (error) {
    let message = calling.t('videoCall.reconnectAttemptError');
    if (document.querySelector("#socketMessage")) {
        document.querySelector("#socketMessage").innerHTML = message;
        document.querySelector("#socketMsg").className = 'callingMessage';
    }
});

prortc.on('reconnect', function (error) {
    let message = calling.t('videoCall.reconnectError');
    if (document.querySelector("#socketMessage")) {
        document.querySelector("#socketMessage").innerHTML = message;
        document.querySelector("#socketMsg").className = 'callingMessage';
        setTimeout(function () {
            document.querySelector("#socketMsg").className = 'callingMessage d-none';
        }, 2000);
    }
});



//=========================================Mute/Unmute call===============================
calling.muteUnmuteCall = () => {
    calling.prortc.muteAudioToggle();
}
//=========================================Video on/off===============================
calling.videoOnOff = () => {
    calling.prortc.muteVideoToggle();
}

calling.sendEvent = (data) => {
    console.log("send event", data);
    calling.prortc.send_event(data);
}

calling.prortc.on('registered', function () {
    calling.setMediaStram();
});

calling.attachRemoteStreamInvitee = (user, event) => {
    let remote_element = '';
    if (user.role === 'doctor') {
        let element = document.querySelector('#remoteVideo');
        element.setAttribute('playsinline', '');
        element.srcObject = event.stream;
        remote_element = document.querySelector('#doctor_thumb');
        // document.querySelector("#remote_user_role").innerHTML = user.role;
        // document.querySelector("#connectMsg").className = 'callingMessage d-none';
        // document.querySelector("#connectThumbMsg").className = 'callingMessage d-none';
    } else if (user.role === 'patient') {
        remote_element = document.querySelector('#patient_thumb');      
    } else if (user.role === 'invitee'){
        remote_element = document.querySelector('#invitee_thumb');
        document.querySelector("#invitee_div").className = 'rightScreen rightScreen--small';        
    }

    remote_element.setAttribute('playsinline', '');
    remote_element.srcObject = event.stream;
}

//========================Attached remote stream=====================
calling.prortc.on('attach_remote_stream', function (data) {
    let { event } = data;
    let user = data.data;
    if (calling.roomData.role === 'invitee') {
        calling.attachRemoteStreamInvitee(user, event);
    } else {
        let remote_element ='';
        if (user.role === 'patient' || user.role === 'doctor') {
            let element = document.querySelector('#remoteVideo');
            element.setAttribute('playsinline', '');
            element.srcObject = event.stream;
            remote_element = document.querySelector('#remoteVideo_thumb');
            document.querySelector("#remote_user_role").innerHTML = calling.t(`users.${user.role}`);
            document.querySelector("#connectMsg").className = 'callingMessage d-none';
            document.querySelector("#connectThumbMsg").className = 'callingMessage d-none';
        } else if (user.role === 'invitee') {
            console.log("invitee assign data",data.data.userId);
            if (calling.firstInviteeId == '' || calling.firstInviteeId == data.data.userId) {
                console.log("first video update");
                remote_element = document.querySelector('#first_invitee_thumb');
                document.querySelector("#first_invitee_div").className = 'rightScreen';
                calling.firstInviteeId = data.data.userId;
            } else {
                console.log("second video update");
                remote_element = document.querySelector('#second_invitee_thumb');
                document.querySelector("#second_invitee_div").className = 'rightScreen';
                calling.secondInviteeId = data.data.userId;
            }            
        }
        remote_element.setAttribute('playsinline', '');
        remote_element.srcObject = event.stream;
    }

});

calling.prortc.on('detach_remote_stream', function (domId) {
    console.log("detach", domId);
    console.log("userdetail", calling.roomData);
    let element = document.querySelector('#' + domId);
    element.srcObject = null;
    if (calling.roomData.role === 'doctor') {
        window.location.href = "/doctor/discharge-summary/" + calling.roomData.room;
    } else {
        window.location.href = "/";
    }
});

calling.prortc.on('end_call_redirection', function () {
    let element = document.querySelector('#localVideo');
    element.srcObject = null;
    setTimeout(function () {
        if (calling.roomData.role === 'doctor') {
            window.location.href = "/doctor/discharge-summary/" + calling.roomData.room;
        } else {
            window.location.href = "/";
        }
    }, 2000);

});

calling.endCall = () => {
    calling.prortc.endCall();
}
