import React, { Component, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import { Switch, Router } from 'react-router-dom'
import RouteWithLayout from './routeWithLayout'
import browserHistory from '../utilities/browserHistory'
import { WithAuth } from '../utilities/withAuth'
import ClinicRoutes from './clinicRoutes'
import AdminRoutes from './adminRoutes'
import DoctorcRoutes from './doctorRoutes'
import PatientRoutes from './patientRoutes'
import UserLayout from '../layouts/auth'
import MainLayout from '../layouts/main'
import LoadingView from '../components/loadingView'
import FullPageLoader from '../components/loadingView/fullPageLoader'


const ScrollToTop = lazy(() => import('../components/scrollToTop'))
const NetworkDetector = lazy(() => import('../components/networkDetector'))
const Login = lazy(() => import('../container/login'))
const ForgotPassword = lazy(()=> import('../container/forgetPassword/userForgotPassword'))
const ResetPassword = lazy(()=> import('../container/resetPassword/resetPasswordClinic'))
const PatientSignup = lazy(()=> import('../container/users/patientList/patientSignup'))
const DoctorSignup = lazy(()=> import('../container/users/doctorList/doctorSignup'))
const inviteePage = lazy(()=> import('../container/invitee'))



class Routes extends Component {

  render() {
    // init()
    const { isLoggedIn } = this.props
    return (
      <Router history={browserHistory}>
        <Suspense
          fallback=
          {
            isLoggedIn ? (
              <MainLayout loading={true}>
                <LoadingView />
              </MainLayout>
            ) : (
              <FullPageLoader />
            )
          }
        >
          <NetworkDetector />
          <ScrollToTop />
          <Switch>
            <RouteWithLayout
              component={Login}
              exact
              layout={WithAuth(UserLayout)}
              path='/'
            />
            <RouteWithLayout
              component={ForgotPassword}
              layout={UserLayout}
              path='/forgot-password'
              exact
            /> 
            <RouteWithLayout
              component={ResetPassword}
              layout={UserLayout}
              path='/reset-password/:token'
              exact
            /> 
            <RouteWithLayout
              component={PatientSignup}
              exact
              layout={WithAuth(UserLayout)}
              path='/patient-signup'
            />
            <RouteWithLayout
              component={DoctorSignup}
              exact
              layout={WithAuth(UserLayout)}
              path='/doctor-signup' 
            />
            <RouteWithLayout
              component={inviteePage}
              exact
              layout={UserLayout}
              path='/invitee/:code/:userId'
            />
            <AdminRoutes />
          </Switch>
          <Switch>
            <ClinicRoutes />
          </Switch>
          <Switch>
            <DoctorcRoutes />
          </Switch>
          <Switch>
            <PatientRoutes />
          </Switch>
        </Suspense>
      </Router>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    userData: state.auth.userData
  }
}


export default connect(mapStateToProps)(Routes)
