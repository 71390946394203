import config from '../config'


const ApiEndPoints = {
  /**
   * Auth API
   */
  adminLogin: {
    url: '/admin/login',
    method: 'POST'
  },
  userLogin: {
    url: '/login',
    method: 'POST'
  },
  changePassword: {
    url: '/account/change-password',
    method: 'POST'
  },
  logout: {
    url: '/account/logout',
    method: 'POST'
  },
  forgetPassword: {
    url: '/admin/forgot-password',
    method: 'POST'
  },
  userForgetPassword: {
    url: '/account/forgot-password',
    method: 'POST'
  },
  userResetPassword: {
    url: '/account/reset-password',
    method: 'POST'
  },

  /**
   * Auth API
   */
  getProfileDetail: {
    url: '/account/me',
    method: 'GET'
  },

  updateProfile: {
    url: '/admin/update-profile',
    method: 'PUT'
  },

  /**
   * CLINICS API
   */
  clinicList: {
    url: '/clinic',
    method: 'GET'
  },
  clinicDetail: (id) => ({
    url: `/clinic/${id}`,
    method: 'GET'
  }),
  addClinic: {
    url: '/clinic',
    method: 'POST'
  },
  changeClinicStatus: (id) => ({
    url: `/clinic/${id}/update-status`,
    method: 'POST'
  }),

  updateClinicDetail: (id) => ({
    url: `/clinic/${id}`,
    method: 'PUT'
  }),
  deleteClinic: (id) => ({
    url: `/clinic/${id}`,
    method: 'DELETE'
  }),

  getPrivilegsList: {
    url: '/privileges',
    method: 'GET'
  },
  changePasswordClinic: {
    url: '/clinic/change-password',
    method: 'POST'
  },
  clinicDoctor: (id) => ({
    url: `/clinic/${id}/doctor`,
    method: 'GET'
  }),
  clinicPatient: (id) => ({
    url: `/clinic/${id}/patient`,
    method: 'GET'
  }),
  clinicSurveyList: {
    url: `/survey`,
    method: 'GET'
  },
  updateSurvey:{
    url: `/survey`,
    method: 'PUT'
  },
  addPatientTool: {
    url: `/tool`,
    method: 'POST'
  },
  getPatientTool: {
    url: `/tool`,
    method: 'GET'
  },
  deleteClinicTool: (id) => ({
    url: `/tool/${id}`,
    method: 'DELETE'
  }),
  clinicTool: (id) => ({
    url: `/tool/${id}`,
    method: 'GET'
  }),
  updateClinicTool: (id) => ({
    url: `/tool/${id}`,
    method: 'PUT'
  }),

  getAllScheduledDoctor: (id)=>({
    url: `/clinic/${id}/appointment/doctor`,
    method: 'GET'
  }),

  /**
  * speciality api 
  */
   addSpeciality: {
    url: `/specility`,
    method: 'POST'
  },
  getSpeciality: {
    url: `/specilities-list`,
    method: 'GET'
  },
  getSpecialityid: (id) => ({
    url: `/specility/${id}`,
    method: 'GET'
  }),
   updateSpeciality: (id) => ({
    url: `/specility/${id}`,
    method: 'PUT'
  }),
  deleteSpeciality: (id) => ({
    url: `/specility/${id}`,
    method: 'DELETE'
  }),
  changeSpecialityStatus: (id) => ({
    url: `/specility/${id}/update-status`,
    method: 'POST'
  }),

  /**
  * patient api 
  */
  getPatientList: {
    url: '/patient',
    method: 'GET'
  },

  addPatient: {
    url: '/patient',
    method: 'POST'
  },

  countryList: {
    url: '/countries',
    method: 'GET'
  },

  stateList: {
    url: '/states',
    method: 'GET'
  },

  languageList: {
    url: '/languages',
    method: 'GET'
  },
  getPatientDetail: (id) => ({
    url: `/patient/${id}`,
    method: 'GET'
  }),

  updatePatientDetail: (id) => ({
    url: `/patient/${id}`,
    method: 'PUT'
  }),

  deletePatientDetail: (id) => ({
    url: `/patient/${id}`,
    method: 'DELETE'
  }),
  changePasswordPatient: {
    url: '/patient/change-password',
    method: 'POST'
  },
  changePatientStatus: (id) => ({
    url: `/patient/${id}/update-status`,
    method: 'POST'
  }),
  signupPatient : {
    url: `/patient/signup`,
    method: 'POST'
  },
  paymentSettingList: {
    url: '/patient/payment-detail',
    method: 'GET'
  },
  addAppointment:{
    url: `/patient/book-patient-appointment`,
    method: 'POST'
  },
  addInstantAppointment:{
    url: `/patient/instant-appointment`,
    method: 'POST'
  },
  rescheduleAppointment: (id) =>({
    url: `/patient/reschedule-appointment/${id}`,
    method: 'POST'
  }),
  cancelAppointment: (id) =>({
    url: `/patient/cancel-appointment/${id}`,
    method: 'GET'
  }),
  getModeOfCommunication: (id) => ({
    url: `/doctor/mode-of-communction/${id}`,
    method: 'GET'
  }),
  getAppointmentList: {
    url: '/patient/appointment',
    method: 'GET'
  },
  addPaymentCheckout:{
    url: "/patient/checkout",
    method: "POST"
  },
  addDoctorReview: {
    url: '/patient/review-rating',
    method: 'POST'
  },

  // Prescription

  addPrescription: {
    url: '/patient-prescription',
    method: 'POST'
  },

  getPrescription: (id) => ({
    url: `/patient-prescription/${id}`,
    method: 'GET'
  }),
  
  // Discharge Summary

  addDischarge: {
    url: '/discharge-summary',
    method: 'POST'
  },
  dischargeList: {
    url: '/discharge-summary',
    method: 'GET'
  },
  deleteDischargeSummary: (id) => ({
    url: `/discharge-summary/${id}`,
    method: 'DELETE'
  }),
  downloadDischargeSummary: (id) => ({
    url: `/download/${id}`,
    method: 'GET'
  }),
  addAppointmentDischarge: {
    url: '/appointment/discharge-summary',
    method: 'POST'
  },
  checkAppointmentDischarge: (code) =>({
    url: `appointment-check/${code}`,
    method: 'GET'
  }),
  getAppointmentDischarge: (id) => ({
    url: `/appointment/discharge-summary/${id}`,
    method: 'GET'
  }),

  // Patient Portal

  addPatientPortal: {
    url: '/patient-portal',
    method: 'POST'
  },
  portalList: {
    url: '/patient-portal',
    method: 'GET'
  },
  deletePatientPortal: (id) => ({
    url: `/patient-portal/${id}`,
    method: 'DELETE'
  }),
  downloadPatientPortal: (id) => ({
    url: `/patient-portal/download/${id}`,
    method: 'GET'
  }),

  // Medical Records

  addMedicalRecordFile: {
    url: '/patient-medical-record',
    method: 'POST'
  },
  medicalRecordList: {
    url: '/patient-medical-record',
    method: 'GET'
  },
  deleteMedicalRecordFile: (id) => ({
    url: `/patient-medical-record/${id}`,
    method: 'DELETE'
  }),
  downloadMedicalRecordFile: (id) => ({
    url: `/patient-medical-record/download/${id}`,
    method: 'GET'
  }),




  /**
   * doctor api 
   */
  getDoctorList: {
    url: '/doctor',
    method: 'GET'
  },
  addDoctor: {
    url: '/doctor',
    method: 'POST'
  },
  getSpecalityList: {
    url: '/specialities',
    method: 'GET'
  },
  getDoctorDetail: (id) => ({
    url: `/doctor/${id}`,
    method: 'GET'
  }),
  updateDoctorDetail: (id) => ({
    url: `/doctor/${id}`,
    method: 'PUT'
  }),
  getDoctorView: (id) => ({
    url: `/doctor/${id}`,
    method: 'GET'
  }),
  deleteDoctor: (id) => ({
    url: `/doctor/${id}`,
    method: 'DELETE'
  }),
  changePasswordDoctor: {
    url: '/doctor/change-password',
    method: 'POST'
  },
  getQuestionnaire: {
    url: '/questioners',
    method: 'GET'
  },
  changeQuestionnaireStatus:(id)=> ({
    url: `/questioner/${id}/update-status`,
    method: 'POST'
  }),
  getFavouriteDoctor : {
    url: '/favourite-doctor',
    method: 'GET'
  },
  addEditFavouriteDoctorList:{
    url: '/patient/favourite-doctor',
    method: 'POST'
  },
  getDoctorDashboardData: {
    url: '/doctor-dashboard',
    method: 'GET'
  },
  signupDoctor : {
    url: `/doctor/signup`,
    method: 'POST'
  },
  changeDoctorStatus: (id) => ({
    url: `/doctor/${id}/update-status`,
    method: 'POST'
  }),

  // Appointment api Doctor Module
  appointmentList: {
    url: '/doctor/appointment',
    method: 'GET'
  },
  appointmentMonthList: {
    url: '/appointment',
    method: 'GET'
  },
  appointmentStatus: (id,status) => ({
    url: `/appointment/status-update/${id}?status=${status}`,
    method: 'PUT'
  }),
  getSingleAppointmentData: (code) => ({
    url: `/appointment/${code}`,
    method: 'GET'
  }),
  
  // Reason for Visit
  getReasonOptions: {
    url: '/reasons',
    method: 'GET'
  },
  getReasonList: (id)=> ({
    url: `/doctor/${id}/reason-of-visit`,
    method: 'GET'
  }),
  addReasons: {
    url: '/doctor/reason-of-visit',
    method: 'POST'
  },
  deleteReason: (id)=> ({
    url: `/doctor/reason-of-visit/${id}`,
    method: 'DELETE'
  }),

  // Schedule

  addSchedule:{
    url: '/schedule',
    method: 'POST'
  },
  getScheduleList:{
    url: '/schedule',
    method: 'GET'
  },
  getDocotorClinicList:{
    url: '/doctor-clinic',
    method: 'GET'
  },
  getSchedule:(id)=>({
    url: `/get-schedule/${id}`,
    method: 'GET'
  }),
  updateSchedule:{
    url: '/update-schedule',
    method: 'POST'
  },
  deleteSchedule:(id)=>({
    url: `/schedule-delete/${id}`,
    method: 'DELETE'
  }),

  // Waiting Room

  addWaitingRoomFile: {
    url: '/waiting-room',
    method: 'POST'
  },
  waitingRoomList: {
    url: '/waiting-room',
    method: 'GET'
  },
  deleteWaitingRoomFile: (id) => ({
    url: `/waiting-room/${id}`,
    method: 'DELETE'
  }),
  downloadWaitingRoomFile: (id) => ({
    url: `/waiting-room/download/${id}`,
    method: 'GET'
  }),

  // Rating & Review api
  reviewRatingList: {
    url: '/review-rating',
    method: 'GET'
  },
  /**
   * Dashboard api 
   */
  dashboardList: {
    url: '/admin/dashboard',
    method: 'GET'
  },
  driverList: {
    url: '/admin/recent-users',
    method: 'GET'
  },
  dashboardUserGraph: {
    url: '/admin/dashboard/line/graph',
    method: 'GET'
  },
  clinicDashboardList:{
    url: '/clinic/dashboard',
    method: 'GET'
  },
  clinicUserGraph: {
    url: '/clinic/dashboard/line/graph',
    method: 'GET'
  },
  clinicDriverList: {
    url: '/clinic/recent-users',
    method: 'GET'
  },

  degreeList: {
    url: '/degrees',
    method: 'GET'

  },

   //Generate Report 

   getGeneratedReport: {
    url: '/admin/report',
    method: 'GET'
  },
  getAuditReport: {
    url: '/admin/audit-report',
    method: 'GET'
  },

  //check email or username api 

  checkEmailAndUserName: {
    url: '/check/email-username',
    method: 'POST'
  },
  checkClinicID: {
    url: '/check/clinic-id',
    method: 'POST'
  },
  checkNpiAndResource: {
    url: '/check/resource-id/npi-number',
    method: 'POST'
  },

  // Socket authentication api

  addSocketToken: {
    url: '/socket',
    method: 'POST'
  },
  getSocketToken: {
    url: '/socket',
    method: 'GET'
  },
  socketAuthenticate: {
    url: '/socket/authentication',
    method: 'GET'
  },

  // Message

  roomList: {
    url : '/chat-room',
    method: 'GET'
  },
  roomDetail: (id)=>({
    url : `/chat-room/${id}`,
    method: 'GET'
  }),
  updateRoomName: (id)=>({
    url : `/chat-room/room-name/${id}`,
    method: 'PUT'
  }),
  updateRoomImage: (id)=>({
    url : `/chat-room/room-image/${id}`,
    method: 'PUT'
  }),
  addRoomMedia: (id)=>({
    url : `/chat-room/send-media/${id}`,
    method: 'POST'
  }),
  chatRoomDoctorList: (id)=>({
    url : `/chat-room/doctor-list/${id}`,
    method: 'GET'
  }),
  approveChatRoom: {
    url : `/accept-chat-room`,
    method: 'PUT'
  },
  checkRoom: {
    url : `/check-room`,
    method: 'GET'
  },
  addChatRoom:{
    url : '/chat-room',
    method: 'POST'
  },
  addmessage:{
    url : '/chat-room/send-message',
    method: 'POST'
  },
  addMemberIntoRoom: {
    url : '/chat-room/add-member',
    method: 'POST'
  },
  downloadChatRoomMedia: (id) =>({
    url: `/chat-room/download-media/${id}`,
    method: 'GET'
  }),
  addInvitee:{
    url : '/guest-user',
    method: 'POST'
  },
  messageUnreadCount: {
    url: '/message/unread-count',
    method: 'GET'
  },
  readMessageStatus: (roomId) =>({
    url: `/chat-read-status?roomId=${roomId}`,
    method: 'GET'
  }),
  chatRoomMessageList: (id) =>({
    url: `/chat-messages/${id}`,
    method: 'GET'
  }),
  
  // Notification
  getNotificationList: {
    url : `/notification-list`,
    method: 'GET'
  },
  getNotificationStatus: {
    url : `/notification/update-read-status`,
    method: 'GET'
  },
  deleteNotification: (id) =>({
    url: `/notification/${id}`,
    method: 'DELETE'
  }),
  notificationUnreadCount: {
    url: '/unread-count',
    method: 'GET'
  },


  // Invitee
  getVerifyInvitee: {
    url : `/verify-invitee`,
    method: 'GET'
  },
  
  /**
   * Media URL
   */
   media: {
    clinicImage: `${config.API_BASE_URL}/media/upload/clinic/image`,
    userImage: `${config.API_BASE_URL}/media/upload/user/image`,
    dischargeFile: `${config.API_BASE_URL}/media/upload/discharge/file`,
    doctorImage: `${config.API_BASE_URL}/media/upload/doctor/image`,
    profileImage: `${config.API_BASE_URL}/media/upload/user/image`,
    waitingRoomFile: `${config.API_BASE_URL}/media/upload/waitingRoom/file`,
    waitingRoomAudio: `${config.API_BASE_URL}/media/upload/waitingRoom/audio`,
    waitingRoomVideo: `${config.API_BASE_URL}/media/upload/waitingRoom/video`,
    roomImage: `${config.API_BASE_URL}/media/upload/chatRoom/image`,
    roomMedia: `${config.API_BASE_URL}/media/upload/chatRoomMedia/media`,
  },
  // socket: {
  //   client: socketIOClient(config.SOCKET_URL)
  // }
}

export default ApiEndPoints