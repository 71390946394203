import { clinicRoutes } from "./sidebarClinic"
import { adminRoutes } from "./sidebarAdmin"
import { doctorRoutes } from "./sidebarDoctor"
import { patientRoutes } from "./sidebarPatient"

export const routesJSON = (t = (arg) => arg, permissions = []) => {
  return (
    (adminRoutes(t))
    .concat(clinicRoutes(t))
    .concat(doctorRoutes(t))
    .concat(patientRoutes(t))
  )
}
 

// const checkPermission = (permissions, moduleKey) => {
//   if (permissions) {
//     let moduleKeyIndex = permissions.findIndex((item) => {
//       return moduleKey === 'send_notification'
//         ? item.moduleKey === moduleKey && item.permission === 'edit'
//         : item.moduleKey === moduleKey
//     })
//     if (moduleKeyIndex > -1) {
//       return ['admin', 'clinic', 'doctor']
//     }
//   }
//   return ['admin']
// }

export const redirectPathIfRequireAuthFails = '/'

export const redirectPathIfNotRequireAuthFails = [
  {
    path: '/dashboard',
    userTypes: ['admin', 'clinic', 'doctor','patient']
  }
]
                                                