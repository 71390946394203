import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from '../components/header'
import SideBar from '../components/sidebar'
import {Layout} from "antd";
import {useRouteMatch} from "react-router-dom";
import { getSessionStorageToken } from "../utilities/common"
import { socketInitFunction, socketDisconnectFunction } from "../utilities/connections"
import { selectUserData } from '../redux/auth/authSlice';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { calling } from "../utilities/calling"
import config from "../config"

const {Content, Footer} = Layout;

const MainLayout = (props) => {
  const { children } = props
  const match = useRouteMatch(); 
  const userData = useSelector(selectUserData);
  const { t } = useTranslation()
  
  useEffect(() =>{
    calling.setLanguage(t)
    if(props.loading === undefined){
      if( getSessionStorageToken() && 
      ['patient','doctor','clinic'].includes(userData.userType) &&
      match.path !== `/video-call/:code` &&
      match.path !== `/${userData.userType}/message/:id` &&
      match.path !== `/${userData.userType}/message`
    ){
    socketInitFunction(userData)
    }
      if(['patient','doctor','clinic'].includes(userData.userType)){
        return () => {
          socketDisconnectFunction()
        }
      }
    }
  },[])

  return (
    <>
      {/* <Header />
      <SideBar />
      {children} */}
      <Layout className="app-layout">
      <SideBar/>
      <Layout>
      <Header />
      
        <Content className={`layout-content`}>
        
        <div className="main-content-wrapper">
          {/* <Routes match={match}/> */}
          
          {children}
          
          </div>
          <Footer>
            <div className="layout-footer-content">
                {config.FOOTER_TEXT}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
    </>
  )
}

MainLayout.propTypes = {
  children: PropTypes.any.isRequired
}

export default MainLayout
