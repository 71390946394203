import APIrequest from "../apiRequest";
import ApiEndPoints from "../../utilities/apiEndPoints";
import logger from "../../utilities/logger";

export const getChatRoomList = async ({ queryParams }) => {
  const payload = {
    ...ApiEndPoints.roomList,
    queryParams,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const getChatRoomDetail = async (id) => {
  const payload = {
    ...ApiEndPoints.roomDetail(id),
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const addEditRoomName = async ({ queryParams }, id) => {
  const payload = {
    ...ApiEndPoints.updateRoomName(id),
    queryParams,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const addEditRoomImage = async ({ queryParams }, id) => {
  const payload = {
    ...ApiEndPoints.updateRoomImage(id),
    queryParams,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const addRoomMedia = async ({ queryParams }, id) => {
  const payload = {
    ...ApiEndPoints.addRoomMedia(id),
    queryParams,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const checkRoom = async ({ queryParams }) => {
  const payload = {
    ...ApiEndPoints.checkRoom,
    queryParams,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const addChatRoom = async (bodyData) => {
  const payload = {
    ...ApiEndPoints.addChatRoom,
    bodyData,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const addmessage = async (bodyData) => {
  const payload = {
    ...ApiEndPoints.addmessage,
    bodyData,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const chatRoomDoctorList = async ({ queryParams }, id) => {
  const payload = {
    ...ApiEndPoints.chatRoomDoctorList(id),
    queryParams,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const approveChatRoom = async ({ queryParams }) => {
  const payload = {
    ...ApiEndPoints.approveChatRoom,
    queryParams,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const addMemberIntoRoom = async (bodyData) => {
  const payload = {
    ...ApiEndPoints.addMemberIntoRoom,
    bodyData,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const downloadChatRoomMedia = async (id) => {
  const payload = {
    ...ApiEndPoints.downloadChatRoomMedia(id),
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const addInviteeService = async (bodyData) => {
  const payload = {
    ...ApiEndPoints.addInvitee,
    bodyData,
  };
  try {
    return await APIrequest(payload);
  } catch (error) {
    logger(error);
    throw error;
  }
};

export const getMessageUnreadCount = async () => {
  const payload = {
      ...ApiEndPoints.messageUnreadCount
  }
  try {
      return await APIrequest(payload)
  } catch (error) {
      logger(error)
      throw error
  }
}

export const readMessageStatusService = async (roomId) => {
  const payload = {
      ...ApiEndPoints.readMessageStatus(roomId)
  }
  try {
      return await APIrequest(payload)
  } catch (error) {
      logger(error)
      throw error
  }
}

export const chatRoomMessageListService = async ({queryParams},id) => {
  const payload = {
    ...ApiEndPoints.chatRoomMessageList(id),
    queryParams
  };
  try {
    return await APIrequest(payload)
  } catch (error) {
    logger(error)
    throw error
  }
}